import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RouteEvent,
  RouteEventName,
  RouteEventValue,
  RouteHistory,
  RouteState,
  RouteStateType,
  SeverityType,
} from "../../features/route/routesHistorySlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";
import {
  approx,
  formatData,
  getAddressView,
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Button } from "../Button/Button";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconArrowToLeft } from "../Icon/Line/ArrowToLeft";
import { IconClock } from "../Icon/Line/Clock";
import { IconDownPoint } from "../Icon/Line/DownPoint";
import { IconDriverBehaviour } from "../Icon/Line/DriverBehaviour";
import { IconEndPoint } from "../Icon/Line/EndPoint";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconKilometers } from "../Icon/Line/Kilometers";
import { IconLocatorOff } from "../Icon/Line/LocatorOff";
import { IconStartPoint } from "../Icon/Line/StartPoint";
import { IconTachometer } from "../Icon/Line/Tachometer";
import { IconUpPoint } from "../Icon/Line/UpPoint";
import { MapMarkerAlert } from "../Marker/MapMarkerAlert";
import { Data, TrackBarMarker } from "../Marker/TrackBarMarker";
import "./DetailsTrackBar.css";
import { ZoomTrackbar } from "./ZoomTrackbar";

export const colorTrackbar = [
  {
    type: "ALARM",
    color: "--global-colors-feedback-danger",
  },
  {
    type: "WARNING",
    color: "--global-colors-feedback-warning",
  },
  {
    type: "INFO",
    color: "--global-colors-ui-primary",
  },
];

interface Range {
  indexStart: number;
  indexEnd: number;
}

export type DriverView = {
  id: number;
  firstName: string;
  lastName: string;
};

interface Position {
  routeStateType: RouteStateType;
  driver: DriverView;
  dynamicFields: {
    latitude: number;
    longitude: number;
    lastUpdate: Date;
    address: string;
    direction: number;
    speed: number;
    fuelLevel: number;
    fuelLevelLiters: number;
    odometer: number;
  };
  events: RouteEvent[];
  covered: number;
  width: number;
  widthPerc: number;
  index?: number;
  routeId: number;
}

interface Event {
  type: {
    id: number;
    category: {
      id: number;
      name: string;
    };
    description: string;
    name: RouteEventName;
    severity: SeverityType;
    hardwareSource: boolean;
  };
  color: string;
  value: RouteEventValue;
  left: number;
  timeStart: Date;
  width: number;
  indexStart: number;
  indexEnd: number;
  leftPerc: number;
  widthPerc: number;
  timeEnd?: Date;
  index: number;
}

interface Cluster {
  indexStart: number;
  indexEnd: number;
  left: number;
  events: Event[];
  leftPerc: number;
}

interface DetailsTrackBarProps {
  data: RouteHistory;
  sendPosition: (e: number) => any;
  getCollapsed: (e: any) => any;
  sendRange?: (range: Range) => {};
  sendPositionTimestamp: (e: string) => any;
  sendRouteId?: (e: number) => void;
  defaultPosition?: string;
  drivers: DriverView[];
  collapseTrackbar?: boolean;
}

export const DetailsTrackBar: React.FC<DetailsTrackBarProps> = ({
  data,
  sendPosition,
  sendRange,
  getCollapsed,
  sendPositionTimestamp,
  sendRouteId,
  defaultPosition: defaultPositionTimestamp,
  drivers,
  collapseTrackbar = false,
}) => {
  const { t } = useTranslation();
  const indexesZoom = [
    100, 110, 125, 150, 175, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000,
    1250, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
  ];
  const numPosition = data.routeStates.length;
  const [defaultEnrichedData, setDefaultEnrichedData] = useState(
    {} as Position
  );
  const [value, setValue] = useState(0);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(data.routeStates.length - 1);
  const [positions, setPositions] = useState([] as Position[]);
  const [dataroutes, setDataroutes] = useState([] as Position[]);
  const [position, setPosition] = useState(data.routeStates[0]);
  const [events, setEvents] = useState([] as Event[]);
  const [clusters, setClusters] = useState([] as Cluster[]);
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundSize: "0% 100%",
  });
  const [isInitial, setIsInitial] = useState(true);
  const [isFinal, setIsFinal] = useState(false);
  const [indexZoom, setIndexZoom] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(collapseTrackbar);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const isBreak = data?.routeStateType === "STOP";
  const SLIDER_THUMB_WIDTH = 20;

  let change = 0;

  useEffect(() => {
    let enrichedData;
    if (data && data.routeStates.length > 1) {
      let length = data.routeStates.length - 1;
      let start = new Date(data.routeStates[0].dynamicFields.lastUpdate);
      let end = new Date(
        data.routeStates[data.routeStates.length - 1].dynamicFields.lastUpdate
      );
      let totaltime = end.getTime() - start.getTime();
      if (totaltime !== 0) {
        // TODO: color must be removed
        enrichedData = data.routeStates.map((place, index) => {
          if (place.events && place.events.length > 0) {
            return {
              ...place,
              events: place.events.map((el) => {
                switch (el?.type?.name) {
                  case "SPEED_LIMIT":
                    return { ...el, color: "#FF4F48" };
                  case "DRIVER_CHANGED":
                  case "DRIVER_IDENTIFIED":
                    return { ...el, color: "#00FFFF" };
                  default:
                    return { ...el, color: "#0000FF" };
                }
              }),
              width:
                (length *
                  (new Date(place.dynamicFields.lastUpdate).getTime() -
                    start.getTime())) /
                totaltime,
              widthPerc:
                (100 *
                  (new Date(place.dynamicFields.lastUpdate).getTime() -
                    start.getTime())) /
                totaltime,
              index: index,
            };
          } else
            return {
              ...place,
              width:
                (length *
                  (new Date(place.dynamicFields.lastUpdate).getTime() -
                    start.getTime())) /
                totaltime,
              widthPerc:
                (100 *
                  (new Date(place.dynamicFields.lastUpdate).getTime() -
                    start.getTime())) /
                totaltime,
              index: index,
            };
        });
        setDataroutes(enrichedData);
        setPositions(enrichedData);

        // If externally provided, the first position of the cursor will be modified

        setStartPosition(0);
        setEndPosition(enrichedData.length - 1);
        setIndexZoom(0);

        document.documentElement.style.setProperty("--margin-left", "0px");

        if (!!defaultPositionTimestamp) {
          if (!!enrichedData && enrichedData.length > 0) {
            const foundtInitialThumble = enrichedData.find((x) => {
              if (
                new Date(x.dynamicFields.lastUpdate).toUTCString() ===
                new Date(defaultPositionTimestamp).toUTCString()
              ) {
                return x;
              }
            });
            if (foundtInitialThumble) {
              setDefaultEnrichedData(foundtInitialThumble);
              setPosition(foundtInitialThumble);
            } else {
              setPosition(enrichedData[0]);
            }
          }
        } else {
          setPosition(enrichedData[0]);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      !!defaultEnrichedData &&
      Object.keys(defaultEnrichedData).length > 0 &&
      defaultEnrichedData.index
    ) {
      sendPosition(defaultEnrichedData.index);
      sendPositionTimestamp(
        defaultEnrichedData?.dynamicFields?.lastUpdate.toString()
      );
      if (sendRouteId) {
        sendRouteId(defaultEnrichedData?.routeId ?? 0);
      }
    }
  }, [defaultEnrichedData]);

  useEffect(() => {
    // UI slider is 100 times larger (10000 vs 100) to handle more points
    let val = value / 100;
    if (val < 0) {
      setBackgroundStyle({
        backgroundSize: 0 + "% 100%",
      });
    } else if (val > 100) {
      setBackgroundStyle({
        backgroundSize: 100 + "% 100%",
      });
    } else {
      setBackgroundStyle({
        backgroundSize: val + "% 100%",
      });
    }
    if (sendRange) {
      sendRange({ indexStart: startPosition, indexEnd: endPosition });
    }
  }, [endPosition, startPosition, value]);

  useEffect(() => {
    if (positions && positions.length > 0) {
      let startTime = new Date(positions[0]?.dynamicFields?.lastUpdate);
      let endTime = new Date(
        positions[positions.length - 1]?.dynamicFields?.lastUpdate
      );
      if (
        indexZoom === indexesZoom.length - 1 ||
        endTime.getTime() - startTime.getTime() < 30000 ||
        positions.length <= 4
      ) {
        setIsFinal(true);
        setIsInitial(false);
      } else if (indexZoom < indexesZoom.length - 1 && indexZoom > 0) {
        setIsInitial(false);
        setIsFinal(false);
      } else {
        setIsInitial(true);
        setIsFinal(false);
      }
    }
  }, [indexZoom]);

  useEffect(() => {
    if (positions && positions.length > 0 && !isCollapsed) {
      processGpsPointsEvents(positions);
      let newPositionIndex = dataroutes.findIndex(
        (el) =>
          el?.dynamicFields.lastUpdate === position?.dynamicFields?.lastUpdate
      );
      if (newPositionIndex <= startPosition) {
        setValue(0);
      } else if (newPositionIndex >= endPosition) {
        setValue(10000);
      } else {
        setValue(positions[newPositionIndex - startPosition].widthPerc * 100);
      }
    }
  }, [positions, isCollapsed]);

  // get collapsed status outside
  useEffect(() => {
    getCollapsed(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    if (defaultPositionTimestamp && defaultPositionTimestamp !== "") {
      compareWrapper(defaultPositionTimestamp);
    }
  }, [defaultPositionTimestamp]);

  //#region INIZIO: funzioni che gestiscono il caricamento della parte inferiore della trackbar
  /**
   * This method is in charge of calculating the widths over each route state
   * in percentuals
   * @param {*} positionsRoutes
   * @returns
   */
  const calcWidths = (positionsRoutes: RouteState[]) => {
    let newPositions: Position[] = [];
    let length = positionsRoutes.length - 1;
    let start = new Date(positionsRoutes[0]?.dynamicFields.lastUpdate);
    let end = new Date(
      positionsRoutes[positionsRoutes.length - 1]?.dynamicFields.lastUpdate
    );
    let totaltime = end.getTime() - start.getTime();
    if (totaltime !== 0) {
      positionsRoutes.forEach((place) => {
        let timeStamp = new Date(place?.dynamicFields.lastUpdate);
        const newWidth =
          (length * (timeStamp.getTime() - start.getTime())) / totaltime;
        const newWidthPerc =
          (100 * (timeStamp.getTime() - start.getTime())) / totaltime;
        let newPlace: Position;
        newPlace = { ...place, width: newWidth, widthPerc: newWidthPerc };
        newPlace.width = newWidth;
        newPlace.widthPerc = newWidthPerc;
        newPositions.push(newPlace);
      });
    }
    return newPositions;
  };

  /**
   * This method is in charge of iterating over each route state
   * and constructing the linear events. The linear events are
   * represented by the variable "eventsOpen", where each one should contains:
   *  - the event itself
   *  - the width (the span of the event over the track bar)
   *  - the starting time
   *  - the starting index
   *  - the ending index
   */
  const processGpsPointsEvents = (routeStates: Position[]) => {
    let eventsArray: Event[] = [];
    let eventsOpen: Event[] = [];
    routeStates.forEach((posit, index) => {
      if (posit.events != null) {
        if (posit.events.length !== 0) {
          posit.events.forEach((ev) => {
            if (eventsOpen.length === 0) {
              let event = {
                ...ev,
                left: posit.width,
                timeStart: posit?.dynamicFields?.lastUpdate,
                width: 0,
                indexStart: index,
                indexEnd: index,
                leftPerc: posit.widthPerc,
                widthPerc: 0,
                index: index,
              };
              eventsOpen.push(event);
            } else {
              let eventFound = eventsOpen.find(
                (evn) =>
                  evn.type.severity === ev.type.severity &&
                  evn.type.description === ev.type.description &&
                  evn.indexEnd === index - 1
              );
              if (eventFound) {
                eventFound.width = posit.width - eventFound.left;
                eventFound.timeEnd = posit?.dynamicFields.lastUpdate;
                eventFound.indexEnd = index;
                eventFound.widthPerc = posit.widthPerc - eventFound.leftPerc;
              } else {
                let event = {
                  ...ev,
                  left: posit.width,
                  timeStart: posit?.dynamicFields?.lastUpdate,
                  width: 0,
                  indexStart: index,
                  indexEnd: index,
                  leftPerc: posit.widthPerc,
                  widthPerc: 0,
                  index: index,
                };
                eventsOpen.push(event);
              }
            }
          });
        } else {
          if (eventsOpen.length > 0) {
            eventsArray = eventsArray.concat(eventsOpen);
            eventsOpen = [];
          }
        }
      }
    });
    if (eventsOpen.length !== 0) {
      eventsArray = eventsArray.concat(eventsOpen);
    }
    eventsArray = eventsArray.filter(
      (el) =>
        el.type.name !== "IGNITION_KEY_ON" &&
        el.type.name !== "START" &&
        el.type.name !== "END" &&
        el.type.name !== "IGNITION_KEY_OFF"
    );
    setEvents(eventsArray);
    if (eventsArray.length) {
      handleCluster(eventsArray);
    } else {
      setClusters([]);
    }
  };

  /**
   * This method is in charge of iterating over each events
   * and constructing the clusters
   *
   * @param {*} eventsArray
   */
  const handleCluster = (eventsArray: Event[]) => {
    let singleEvents: Event[] = [];
    eventsArray.forEach((ev) => {
      if (ev.indexEnd - ev.indexStart === 0) {
        singleEvents.push({
          ...ev,
          index: ev.indexStart,
          timeStart: ev.timeStart,
        });
      } else {
        let ev_1 = { ...ev, index: ev.indexStart, timeStart: ev.timeStart };
        let ev_2 = {
          ...ev,
          index: ev.indexEnd,
          left: ev.left + ev.width,
          leftPerc: ev.leftPerc + ev.widthPerc,
          timeStart: ev.timeEnd ? ev.timeEnd : ev.timeStart,
        };
        singleEvents.push(ev_1, ev_2);
      }
    });
    singleEvents.sort((a, b) => a.index - b.index);
    let cluster: Cluster;
    let clustersArray: Cluster[] = [];
    let toll = 40;
    singleEvents.forEach((evn, ind) => {
      if (!cluster) {
        cluster = {
          indexStart: evn.index,
          indexEnd: evn.index,
          left: evn.left,
          events: [evn],
          leftPerc: evn.leftPerc,
        };
      } else {
        if (
          convertWidthToPix(evn.leftPerc) -
            convertWidthToPix(cluster.leftPerc) <
          toll
        ) {
          cluster.indexEnd = evn.index;
          cluster.events.push(evn);
        } else {
          clustersArray.push(cluster);
          cluster = {
            indexStart: evn.index,
            indexEnd: evn.index,
            left: evn.left,
            events: [evn],
            leftPerc: evn.leftPerc,
          };
        }
      }
      if (ind === singleEvents.length - 1) {
        clustersArray.push(cluster);
      }
    });
    setClusters(clustersArray);
  };

  const convertWidthToPix = (width: number) => {
    let widthElement: HTMLElement | null =
      document.getElementById("trackBar_slider");
    if (widthElement) {
      let widthTot = widthElement.offsetWidth - SLIDER_THUMB_WIDTH;
      return (width * widthTot) / 100;
    } else {
      return 0;
    }
  };

  const calcMargin = (value: number, valueApprox: any) => {
    let l = (value * 20) / 100;
    let diff = convertWidthToPix(valueApprox - value);
    let mrgn = `${l + diff - 10}px`;
    return mrgn;
  };

  const getPercentInCluster = (eventsCluster: Event[], type: string) => {
    let num = eventsCluster.reduce(
      (total, x) => (x.type.severity === type ? total + 1 : total),
      0
    );
    return (num * 100) / eventsCluster.length;
  };

  const getIcon = (cluster: Cluster, index: number) => {
    return (
      <React.Fragment key={index}>
        <div
          className="mn-track-bar-point"
          style={{
            /* 8 and 18 are adjustments needed to center marker icons (width 16 and 36px)*/
            marginLeft: `calc(${cluster.leftPerc}% - ${
              cluster.events.length === 1 ? 8 : 18
            }px)`,
            top: `${cluster.events.length === 1 ? 5 : -15}px`,
          }}
          key={index}
        >
          {cluster.events.length === 1
            ? getSingleMarker(cluster)
            : getMultiMarker(cluster)}
        </div>
      </React.Fragment>
    );
  };

  const getSingleMarker = (cluster: Cluster) => {
    return (
      <MapMarkerAlert
        width={16}
        color={`${
          colorTrackbar.find(
            (el) => el.type === cluster.events[0].type.severity
          )?.color
        }`}
        onClick={() => {
          let newVal = (
            100 * positions[cluster.indexStart].widthPerc
          ).toString();

          compare(newVal);
        }}
      />
    );
  };

  const getMultiMarker = (cluster: Cluster) => {
    if (!cluster.events.every((el) => el.left === cluster.events[0].left)) {
      return cluster.events.every(
        (el) => el.type.severity === cluster.events[0].type.severity
      )
        ? getCluster(true, cluster)
        : getCluster(false, cluster);
    } else {
      return getMarkersSameTop(cluster);
    }
  };

  const getCluster = (
    isSameType: boolean,
    cluster: Cluster,
    disabled: boolean = false
  ) => {
    return (
      <TrackBarMarker
        isClustered
        text={cluster.events.length.toString()}
        color={
          isSameType
            ? `${
                colorTrackbar.find(
                  (el) => el.type === cluster.events[0].type.severity
                )?.color
              }`
            : "--global-colors-ui-white"
        }
        textColor={
          isSameType
            ? cluster.events[0].type.severity !== "WARNING"
              ? "--global-colors-ui-white"
              : "--global-colors-ink-dark"
            : "--global-colors-ink-dark"
        }
        data={isSameType ? undefined : getDataCluster(cluster)}
        onClick={(e) => {
          if (!disabled && e.detail === 2) {
            clickOnCluster(cluster);
          }
        }}
      />
    );
  };

  const getDataCluster = (cluster: Cluster) => {
    let dataCluster: Data[] = [];
    colorTrackbar.forEach((el) => {
      let element = {
        color: `var(${el.color})`,
        percentage: getPercentInCluster(cluster.events, el.type),
      };
      dataCluster.push(element);
    });
    return dataCluster;
  };

  const getMarkersSameTop = (cluster: Cluster) => {
    let sameType = cluster.events.every(
      (el) => el.type.severity === cluster.events[0].type.severity
    );
    if (sameType) {
      return getCluster(true, cluster, true);
    } else {
      return getCluster(false, cluster, true);
    }
  };

  const getSegment = (event: Event, index: number) => {
    if (event.width) {
      return (
        <div
          className="mn-track-bar__segment"
          style={{
            background: `var(${
              colorTrackbar.find((el) => el.type === event.type.severity)?.color
            })`,
            left: `calc(${event.leftPerc}% + ${
              SLIDER_THUMB_WIDTH / 2 -
              (event.leftPerc * SLIDER_THUMB_WIDTH) / 100
            }px)`,
            width: `calc(${event.widthPerc}% - ${
              (event.widthPerc * SLIDER_THUMB_WIDTH) / 100
            }px`,
          }}
          key={index}
        ></div>
      );
    } else {
      return null;
    }
  };

  const getTime = (index: number, cluster: Cluster) => {
    return (
      <React.Fragment key={index}>
        <div
          className="mn-track-bar-time"
          style={{
            marginLeft: `calc(${cluster.leftPerc}% - 15px)`,
          }}
          key={index}
        >
          <div style={{ width: "30px", textAlign: "center" }}>
            {getFormattedTimeApprox(cluster.events[0].timeStart)}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getStartOrEnd = (moment: string) => {
    return (
      <React.Fragment key={moment}>
        <div
          className="mn-track-bar-time"
          style={{
            marginLeft:
              moment === "start" ? `calc(0% - 20px)` : `calc( 99% - 10px)`,
          }}
          key={moment}
        >
          <div style={{ width: "40px", textAlign: "center" }}>
            {getFormattedTimeApprox(
              moment === "start"
                ? data.firstPosition.gpsPositionTimestamp
                : data.lastPosition.gpsPositionTimestamp
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getClassArrow = (posit: string) => {
    switch (posit) {
      case "start":
        return isInitial === true || startPosition === 0
          ? "arrow-disabled"
          : "arrow";
      case "end":
        return isInitial === true || endPosition === numPosition - 1
          ? "arrow-disabled"
          : "arrow";
      default:
    }
  };

  const getIconStart = () => {
    if (startPosition === 0) {
      return (
        <div
          className="mn-track-bar-point-icon-start"
          style={{
            marginLeft: `-9px`,
          }}
          key={"start"}
        >
          {isBreak ? <IconDownPoint size={18} /> : <IconStartPoint size={18} />}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getIconEnd = () => {
    if (endPosition === numPosition - 1) {
      return (
        <div
          className="mn-track-bar-point-icon-end"
          style={{
            marginLeft: `calc(${100}% - 9px)`,
          }}
          key={"end"}
        >
          {isBreak ? <IconUpPoint size={18} /> : <IconEndPoint size={18} />}
        </div>
      );
    } else {
      return <></>;
    }
  };

  //#endregion

  //#region INIZIO: funzioni che gestiscono le informazioni della parte superiore della trackbar
  const getFuelPer = (fuelLevel: number) => {
    if (fuelLevel === 0) {
      return `0% (0 ${preferencesContext.isMetric ? "lt" : "gal"})`;
    } else {
      return `${formatData(Number(fuelLevel), ltToGal, preferencesContext, 1)}
      ${preferencesContext.isMetric ? " lt" : " gal"}`;
    }
  };

  const getField = (posit: RouteState, field: string) => {
    switch (field) {
      case "location":
        return posit?.dynamicFields?.address
          ? getAddressView(posit.dynamicFields.address)
          : t("common.na");

      case "driver":
        const filteredDrivers = drivers.filter((x) => x.id === posit.driver.id);
        const driver =
          filteredDrivers.length > 0 ? filteredDrivers[0] : ({} as DriverView);
        return driver?.firstName && driver?.lastName
          ? `${driver.firstName} ${driver.lastName}`
          : t("common.na");
      case "direction":
        return posit?.dynamicFields?.direction
          ? `${posit.dynamicFields.direction} °`
          : t("common.na");

      case "currentSpeed":
        return posit && posit.dynamicFields?.speed !== undefined
          ? preferencesContext.isMetric
            ? posit.dynamicFields?.speed + " km/h"
            : kmPerHToMilesPerH(posit?.dynamicFields?.speed).toFixed(0) + " mph"
          : t("common.na");

      case "odometer":
        return posit && posit?.dynamicFields?.odometer
          ? preferencesContext.isMetric
            ? numberAnnotation(mtToKm(posit?.dynamicFields?.odometer), 1) +
              " km"
            : numberAnnotation(
                kmToMiles(mtToKm(posit?.dynamicFields?.odometer)),
                1
              ) + " mil"
          : t("common.na");

      case "fuelLevelLiters":
        return posit?.dynamicFields?.fuelLevelLiters
          ? getFuelPer(posit.dynamicFields.fuelLevelLiters)
          : t("common.na");

      case "currentTime":
        return posit && posit?.dynamicFields?.lastUpdate
          ? getFormattedTime(posit?.dynamicFields?.lastUpdate, true)
          : t("common.na");

      default:
        return null;
    }
  };

  const getFormattedTime = (time: Date, withSeconds: boolean) => {
    // TODO: rifinire l'ora in base alla timezona
    const hours = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    const seconds = new Date(time).getSeconds();

    return withSeconds
      ? `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
          seconds < 10 ? "0" + seconds : seconds
        }`
      : `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  };

  const getFormattedTimeApprox = (time: Date) => {
    // TODO: rifinire l'ora in base alla timezona
    const seconds = new Date(time).getSeconds();

    const coeff = 1000 * 60 * 0.5;

    const date = new Date(time);
    if (seconds >= 30) {
      return getFormattedTime(new Date(date.getTime() + coeff), false);
    } else {
      return getFormattedTime(date, false);
    }
  };
  //#endregion

  //#region INIZIO: funzioni di click sulla trackbar

  /**
   * This method is in charge of clicking on + button
   * and constructing new positions
   */
  const clickOnZoom = () => {
    let newWidth = approx(
      positions.length * (indexesZoom[indexZoom] / indexesZoom[indexZoom + 1])
    );
    let newEndPosition = approx(startPosition + newWidth - 1);
    let newPositions = dataroutes.slice(startPosition, newEndPosition + 1);
    const newPositionsWithWidths = calcWidths(newPositions);
    setEndPosition(newEndPosition);
    setIndexZoom(indexZoom + 1);
    setPositions(newPositionsWithWidths);
  };

  /**
   * This method is in charge of clicking on - button
   * and constructing new positions
   */
  const clickOutZoom = () => {
    let newWidth = approx(
      positions.length * (indexesZoom[indexZoom] / indexesZoom[indexZoom - 1])
    );
    let diff_w = (newWidth - positions.length) / 2;
    let newEndPosition;
    let newStartPosition;
    if (Number.isInteger(diff_w)) {
      newEndPosition = endPosition + diff_w;
      newStartPosition = startPosition - diff_w;
    } else {
      newEndPosition = endPosition + Math.floor(diff_w);
      newStartPosition = startPosition - Math.ceil(diff_w);
    }
    if (newEndPosition > numPosition - 1) {
      newStartPosition = Math.max(
        newStartPosition - (newEndPosition - numPosition + 1),
        0
      );
      newEndPosition = numPosition - 1;
    }
    if (newStartPosition < 0) {
      newEndPosition = Math.min(
        newEndPosition + Math.abs(newStartPosition),
        numPosition - 1
      );
      newStartPosition = 0;
    }
    let newPositions = dataroutes.slice(newStartPosition, newEndPosition + 1);
    const newPositionsWithWidths = calcWidths(newPositions);
    setStartPosition(newStartPosition);
    setEndPosition(newEndPosition);
    setIndexZoom(indexZoom - 1);
    setPositions(newPositionsWithWidths);
  };

  /**
   * This method is in charge of clicking on Refresh button
   * and constructing new positions
   */
  const clickOnRefresh = () => {
    setEndPosition(dataroutes.length - 1);
    setStartPosition(0);
    setIndexZoom(0);
    const newPositionsWithWidths = calcWidths(dataroutes);
    setPositions(newPositionsWithWidths);
  };

  /**
   * This method is in charge of clicking on <- button
   * and constructing new positions
   */
  const clickOnLeftArrow = () => {
    let trasl = (positions.length * 10) / 100;
    trasl = trasl < 1 ? 1 : trasl;
    let newStartPosition = approx(
      startPosition < trasl ? 0 : startPosition - trasl
    );
    let newEndPosition = approx(newStartPosition + positions.length - 1);
    let newPositions = dataroutes.slice(newStartPosition, newEndPosition + 1);
    const newPositionsWithWidths = calcWidths(newPositions);
    setStartPosition(newStartPosition);
    setEndPosition(newEndPosition);
    setPositions(newPositionsWithWidths);
  };

  /**
   * This method is in charge of clicking on -> button
   * and constructing new positions
   */
  const clickOnRightArrow = () => {
    let trasl = (positions.length * 10) / 100;
    trasl = trasl < 1 ? 1 : trasl;
    let newEndPosition = approx(
      numPosition - endPosition - 1 < trasl
        ? numPosition - 1
        : endPosition + trasl
    );
    let newStartPosition = approx(newEndPosition - positions.length + 1);
    let newPositions = dataroutes.slice(newStartPosition, newEndPosition + 1);
    const newPositionsWithWidths = calcWidths(newPositions);
    setStartPosition(newStartPosition);
    setEndPosition(newEndPosition);
    setPositions(newPositionsWithWidths);
  };

  /**
   * This method is in charge of zooming on trackabar considering wheel scroll
   * @param {*} event
   */
  const zoomByWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.deltaY < 0 && indexZoom !== indexesZoom.length - 1) {
      clickOnZoom();
    } else if (event.deltaY > 0 && indexZoom !== 0) {
      clickOutZoom();
    }
  };

  /**
   * This method is in charge of clicking on a cluster
   *
   * @param {*} cluster
   */
  const clickOnCluster = (cluster: Cluster) => {
    let cond = true;
    let index = indexZoom;
    let newEndPosition = endPosition;
    let newStartPosition = startPosition;
    let newPositions = positions;
    let newWidth: number;
    while (cond && index !== indexesZoom.length - 1) {
      newWidth = approx(
        newPositions.length * (indexesZoom[index] / indexesZoom[index + 1])
      );

      let diff = (newWidth - (cluster.indexEnd - cluster.indexStart)) / 2;

      if (Number.isInteger(diff)) {
        newEndPosition = cluster.indexEnd + diff - 1;
        newStartPosition = cluster.indexStart - diff;
      } else {
        newEndPosition = cluster.indexEnd + Math.floor(diff) - 1;
        newStartPosition = cluster.indexStart - Math.ceil(diff);
      }

      if (newEndPosition > numPosition - 1) {
        newStartPosition = Math.max(
          newStartPosition - (newEndPosition - numPosition + 1),
          0
        );
        newEndPosition = numPosition - 1;
      }
      if (newStartPosition < 0) {
        newEndPosition = Math.min(
          newEndPosition + Math.abs(newStartPosition),
          numPosition - 1
        );
        newStartPosition = 0;
      }

      newPositions = calcWidths(
        dataroutes.slice(newStartPosition, newEndPosition + 1)
      );
      cond =
        convertWidthToPix(
          newPositions[cluster.indexEnd - newStartPosition].widthPerc
        ) -
          convertWidthToPix(
            newPositions[cluster.indexStart - newStartPosition].widthPerc
          ) <
        40;
      index = index + 1;
    }
    setIndexZoom(index);
    setStartPosition(newStartPosition);
    setEndPosition(newEndPosition);
    setPositions(newPositions);
  };

  /**
   * This method is in charge of clicking on a point
   * in the trackbar
   * @param {*} val
   */
  const compareWrapper = (timestamp: string) => {
    if (positions) {
      const filteredPositions: any = positions.find(
        (x: any) => x.dynamicFields.lastUpdate === timestamp
      );
      if (filteredPositions) {
        compare((filteredPositions.widthPerc * 100).toString());
      }
    }
  };

  const compare = (val: string) => {
    //calculate value on the slider
    let newVal = Number(val) / 100;

    //find indexes where newVal is between them
    let index = positions.findIndex((x) => x.widthPerc >= newVal);
    let inf = positions[index - 1] ? positions[index - 1].widthPerc : 0;
    let sup = positions[index] ? positions[index].widthPerc : 100;
    let mrg = "";

    //newVal is nearer to inf
    if (sup - newVal >= newVal - inf) {
      let computedPosition =
        index - 1 < 0 ? startPosition : index + startPosition - 1;
      setValue(inf * 100);
      sendPosition(computedPosition);
      setPosition(
        index - 1 < 0
          ? dataroutes[startPosition]
          : dataroutes[index + startPosition - 1]
      );
      sendPositionTimestamp(
        index - 1 < 0
          ? dataroutes[startPosition].dynamicFields.lastUpdate.toString()
          : dataroutes[
              index + startPosition - 1
            ].dynamicFields.lastUpdate.toString()
      );
      if (sendRouteId) {
        sendRouteId(
          index - 1 < 0
            ? dataroutes[startPosition].routeId
            : dataroutes[index + startPosition - 1].routeId
        );
      }
      mrg = calcMargin(newVal, inf);
      if (
        change === 1 &&
        clusters.some(
          (x) =>
            x.indexStart ===
            (index - 1 < 0 ? startPosition : index + startPosition - 1)
        )
      ) {
        document.documentElement.style.setProperty("--margin-left", mrg);
      } else {
        document.documentElement.style.setProperty("--margin-left", "0px");
      }
    } else {
      //newVal is nearer to sup
      setValue(sup * 100);
      sendPosition(index + startPosition);
      setPosition(dataroutes[index + startPosition]);
      sendPositionTimestamp(
        dataroutes[index + startPosition].dynamicFields.lastUpdate.toString()
      );
      if (sendRouteId) {
        sendRouteId(dataroutes[index + startPosition].routeId);
      }
      mrg = calcMargin(newVal, sup);
      if (
        change === 1 &&
        clusters.some((x) => x.indexStart === index + startPosition)
      ) {
        document.documentElement.style.setProperty("--margin-left", mrg);
      } else {
        document.documentElement.style.setProperty("--margin-left", "0px");
      }
    }
  };

  //#endregion

  return (
    <div>
      {!isCollapsed ? (
        <div className="mn-track-bar-collapse">
          <div className="mn-track-bar-collapse-first">
            <div className="mn-track-bar-coll-title">
              <Button
                size="small"
                aspect="ghost"
                label={i18next.t("common.collapse")}
                onClick={() => {
                  setIsCollapsed((collapse) => !collapse);
                }}
              >
                <IconArrowToLeft size={14} color="--global-colors-ink-dark" />
              </Button>
            </div>
          </div>
          <div className="mn-track-bar-coll"></div>
        </div>
      ) : (
        <div></div>
      )}
      {!isCollapsed ? (
        <div
          className="mn-track-bar"
          id="trackbar"
          onWheel={(e) => {
            zoomByWheel(e);
          }}
        >
          {position && (
            <div className="mn-track-bar__upper">
              <div>
                {getField(position, "location") && !isBreak && (
                  <DataLabel
                    dataText={getField(position, "location") ?? "N/A"}
                    icon={<IconLocatorOff size={14} />}
                    label={t("locationHistory.trackBar.location")}
                  />
                )}
                {getField(position, "driver") && (
                  <DataLabel
                    dataText={getField(position, "driver") ?? "N/A"}
                    icon={<IconDriverBehaviour size={14} />}
                    label={t("locationHistory.trackBar.driver")}
                  />
                )}
              </div>
              {!isBreak && (
                <>
                  <div></div>
                  <div>
                    {getField(position, "odometer") && (
                      <DataLabel
                        dataText={getField(position, "odometer") ?? "N/A"}
                        icon={<IconKilometers size={14} />}
                        label={t("locationHistory.trackBar.odometer")}
                      />
                    )}
                  </div>
                </>
              )}
              <div>
                {getField(position, "currentTime") && (
                  <DataLabel
                    dataText={getField(position, "currentTime") ?? "N/A"}
                    icon={<IconClock size={14} />}
                    label={t("locationHistory.trackBar.currentTime")}
                  />
                )}
              </div>
              <div>
                {getField(position, "currentSpeed") && !isBreak && (
                  <DataLabel
                    dataText={getField(position, "currentSpeed") ?? "N/A"}
                    icon={<IconTachometer size={14} />}
                    label={t("locationHistory.trackBar.currentSpeed")}
                  />
                )}
                {!isBreak && getField(position, "fuelLevelLiters") && (
                  <DataLabel
                    dataText={getField(position, "fuelLevelLiters") ?? "N/A"}
                    icon={<IconGasStation size={14} />}
                    label={t("locationHistory.trackBar.fuelLevel")}
                  />
                )}
              </div>
              <div>
                <ZoomTrackbar
                  isInitial={isInitial}
                  isFinal={isFinal}
                  zoom={indexesZoom[indexZoom]}
                  zoomIn={() => {
                    setIsInitial(false);
                    clickOnZoom();
                  }}
                  zoomOut={() => {
                    clickOutZoom();
                  }}
                  refresh={() => {
                    setIsInitial(true);
                    setIsFinal(false);
                    clickOnRefresh();
                  }}
                />
              </div>
            </div>
          )}
          <div
            className="mn-track-bar__lower"
            onWheel={(e) => {
              zoomByWheel(e);
            }}
          >
            <span className={getClassArrow("start")}>
              <IconArrowLeft size={14} onClick={() => clickOnLeftArrow()} />
            </span>
            <div className="mn-track-bar__slider">
              <div
                className="mn-track-bar__points"
                style={{ position: "relative" }}
              >
                {getIconStart()}
                {clusters.map((cluster, index) => {
                  return getIcon(cluster, index);
                })}
                {getIconEnd()}
              </div>
              <div
                id="trackBar_slider"
                className="slider"
                style={{ position: "relative" }}
              >
                <input
                  type="range"
                  min={0}
                  max={10000}
                  value={value}
                  style={backgroundStyle}
                  onChange={(e) => {
                    compare(e.target.value);
                  }}
                  onMouseUp={() => {
                    change = 1;
                  }}
                />
                {events.map((event, index) => {
                  return getSegment(event, index);
                })}
                <input
                  id="input_up"
                  type="range"
                  min={0}
                  max={10000}
                  value={value}
                  style={backgroundStyle}
                  onChange={(e) => {
                    compare(e.target.value);
                  }}
                  onMouseUp={() => {
                    change = 1;
                  }}
                />
              </div>
              <div className="mn-track-bar__points-time">
                {startPosition === 0 && getStartOrEnd("start")}
                {clusters &&
                  clusters.map((cluster, index) => {
                    return getTime(index, cluster);
                  })}
                {endPosition === numPosition - 1 && getStartOrEnd("end")}
              </div>
            </div>
            <span className={getClassArrow("end")}>
              <IconArrowRight size={14} onClick={() => clickOnRightArrow()} />
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: "12px",
          }}
          className="mn-track-bar-collapsed"
        >
          {isBreak && (
            <div className="mn-track-bar__upper-break">
              <div>
                {getField(position, "driver") && (
                  <DataLabel
                    dataText={getField(position, "driver") ?? "N/A"}
                    icon={<IconDriverBehaviour size={14} />}
                    label={t("locationHistory.trackBar.driver")}
                  />
                )}
              </div>
              <div>
                {getField(position, "currentTime") && (
                  <DataLabel
                    dataText={getField(position, "currentTime") ?? "N/A"}
                    icon={<IconClock size={14} />}
                    label={t("locationHistory.trackBar.currentTime")}
                  />
                )}
              </div>
              <div
                id="icon"
                onClick={() => {
                  setIsCollapsed((collapse) => !collapse);
                }}
              >
                <IconArrowToLeft size={14} color="--global-colors-ink-dark" />
              </div>
            </div>
          )}
          {!isBreak && (
            <div className="mn-track-bar__upper-collapsed">
              <div id="location">
                {getField(position, "location") && !isBreak && (
                  <DataLabel
                    dataText={getField(position, "location") ?? "N/A"}
                    icon={<IconLocatorOff size={14} />}
                    label={t("locationHistory.trackBar.location")}
                  />
                )}
              </div>
              <div>
                {getField(position, "driver") && (
                  <DataLabel
                    dataText={getField(position, "driver") ?? "N/A"}
                    icon={<IconDriverBehaviour size={14} />}
                    label={t("locationHistory.trackBar.driver")}
                  />
                )}
              </div>
              <div>
                {getField(position, "currentTime") && (
                  <DataLabel
                    dataText={getField(position, "currentTime") ?? "N/A"}
                    icon={<IconClock size={14} />}
                    label={t("locationHistory.trackBar.currentTime")}
                  />
                )}
              </div>
              <div>
                {getField(position, "odometer") && (
                  <DataLabel
                    dataText={getField(position, "odometer") ?? "N/A"}
                    icon={<IconKilometers size={14} />}
                    label={t("locationHistory.trackBar.odometer")}
                  />
                )}
              </div>
              <div>
                {!isBreak && (
                  <DataLabel
                    dataText={getField(position, "currentSpeed") ?? "N/A"}
                    icon={<IconTachometer size={14} />}
                    label={t("locationHistory.trackBar.currentSpeed")}
                  />
                )}
              </div>
              <div>
                {getField(position, "fuelLevelLiters") && !isBreak && (
                  <DataLabel
                    dataText={getField(position, "fuelLevelLiters") ?? "N/A"}
                    icon={<IconGasStation size={14} />}
                    label={t("locationHistory.trackBar.fuelLevel")}
                  />
                )}
              </div>
              <div id="icon">
                <Button
                  label=""
                  size="small"
                  aspect="ghost"
                  onlyIcon={true}
                  onClick={() => {
                    setIsCollapsed((collapse) => !collapse);
                  }}
                >
                  <IconArrowToLeft size={14} color="--global-colors-ink-dark" />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
