import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { DetailsTrackBar } from "../../ui/LocationHistory/DetailsTrackBar";
import { EventDetail } from "../../ui/LocationHistory/EventDetail";
import { ClassPolyline } from "../../ui/LocationHistory/Polyline";
import { StopDetails } from "../../ui/LocationHistory/StopDetails";
import { TrackDetails } from "../../ui/LocationHistory/TrackDetails";
import {
  VehicleArrowDisabledProps,
  ViewArrow,
} from "../../ui/LocationHistory/ViewArrow";
import { Actions } from "../../ui/Map/Actions";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { Zoom } from "../../ui/Map/Zoom";
import { ClusterMarker } from "../../ui/Marker/ClusterMarker";
import { ClusterMarkerEvent } from "../../ui/Marker/ClusterMarkerEvent";
import { MapMarkerEventLocation } from "../../ui/Marker/MapMarkerEventLocation";
import { MapMarkerEventToggle } from "../../ui/Marker/MapMarkerEventToggle";
import { MapMarkerLocation } from "../../ui/Marker/MapMarkerLocation";
import { MapMarkerLocator } from "../../ui/Marker/MapMarkerLocator";
import { MapMarkerPin } from "../../ui/Marker/MapMarkerPin";
import { formatTotalTime } from "../../utils/DateAndTimeUtils";
import { Driver, driversSelectors } from "../driver/driversSlice";
import { Event } from "../event/eventsSlice";
import { Geofence, geofencesSelectors } from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { VehicleTravelSummary } from "../report/vehicle/vehicleTravelsSummarySlice";
import {
  RouteEvent,
  RouteHistory,
  RouteState,
} from "../route/routesHistorySlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./LocationHistoryMap.css";

interface LocationHistoryMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  latitude: number;
  longitude: number;
  summaryTrack?: VehicleTravelSummary;
  infoTrack: RouteHistory | undefined;
  openTrackDetails: boolean;
  openStopDetails: boolean;
  expanded: boolean;
  currentActiveType?: any;
  viewPolylineMarkers: boolean;
  isDisableArrow: VehicleArrowDisabledProps | undefined;
  openTrackBar: boolean;
  expandedMain?: boolean;
  currentRouteStateTimestamp?: string;
  sendCurrentTrackBarPosition?: (e: string) => any;
  eventType?: Event[];
  selectedEvents?: Event[];
  openTrackOrStop?: boolean;
}

interface RouteEventReduced {
  event: RouteEvent;
  count: number;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

interface EventInfo {
  id: number;
  name: string;
  speed?: number;
  timestamp?: string;
  routeId?: number;
  queryParams?: string;
}

interface RouteInfo {
  id: number;
  start: string;
  end: string;
  duration: string;
  address: string;
}

interface MarkerData {
  id: string;
  latitude: number;
  longitude: number;
  address: string;
  eventInfo: EventInfo;
  routeInfo: RouteInfo;
  showTooltip: boolean | undefined;
  component: any;
  eventType: string;
}

let geofenceArea: any = null;
let geofenceClusterMarkers: MarkerClusterer;
let geofenceMarkers: google.maps.Marker[] = [];

export const LocationHistoryMap: React.FC<LocationHistoryMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  latitude,
  longitude,
  summaryTrack,
  infoTrack,
  openTrackDetails,
  openStopDetails,
  expanded,
  viewPolylineMarkers,
  openTrackBar,
  isDisableArrow,
  currentRouteStateTimestamp,
  sendCurrentTrackBarPosition,
  eventType,
  selectedEvents,
  openTrackOrStop,
}) => {
  let googleMap = window.google;
  const [map, setMap] = useState<any>();
  const [value, setValue] = useState(0);
  const [trackBarCollapsed, setTrackBarCollapsed] = useState(false);
  const stopMarker: any = useRef(null);
  const prevCoordsRef: any = useRef(); // dato precedente sul coords
  const [summaryMarkers, setSummaryMarkers] = useState<MarkerClusterer>();
  const [prevInfoTrackForTrackBar, setPrevInfoTrackForTrackBar] =
    useState(infoTrack);
  const [prevInfoTrack, setPrevInfoTrack] = useState<RouteHistory>(
    {} as RouteHistory
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [prevSelectedEvents, setPrevSelectedEvents] = useState<Event[]>([]);

  let markerClusterList: any[] = [];
  /**
   * @todo The useRef prevents some re-renders, as opposed to useState.
   * It can happen that some useEffects don't get triggered.
   * Assess whether the polyRef can be transformed into a react state in order to trigger some behaviors
   * (e.g., the incremental colouration).
   * The polylineCreated hook temporarily solves the problem.
   */
  const polyRef: any = useRef(null); // istanza classe polylinea
  const [polylineCreated, setPolylineCreated] = useState(false);
  const navigate = useNavigate();
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [directionArrow, setDirectionArrows] =
    useState<boolean | undefined>(false);
  const [streetViewEnabled, setStreetViewEnabled] = useState(false);
  const [arrows, setArrows] = useState<google.maps.Marker[]>([]);
  const [clusterArrow, setClusterArrow] = useState<any[]>([]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let infoWindow: google.maps.InfoWindow;
  const [timestampFromUrl, setTimestampFromUrl] = useState<any>();

  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );

  //#region this method is in charge to add components on map
  useEffect(() => {
    const componentsOnMapArray: ComponentOnMap[] = [];
    if (googleMap && map && isMapIdle) {
      componentsOnMapArray.push({
        selectorName: ".action-control",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <div
            className="action-control"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px 16px 8px 16px",
            }}
          >
            <Actions
              isFull={true}
              handleFullScreen={() => fullsc()}
              handleScaleDown={() => fullsc()}
              searchDisabled={true}
              settingsDisabled={true}
              geofenceMenuProps={{
                setGeofences: setGeofences,
              }}
              geofencesProp={{
                geofences: geofences,
                geofenceCategories: geofenceCategories,
              }}
            />
          </div>
        ),
      });
      preferencesContext.satelliteOnMap
        ? map.setMapTypeId("hybrid")
        : map.setMapTypeId("roadmap");

      componentsOnMapArray.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
        component: (
          <MapType
            handleMap={() => mapClick()}
            handleSatellite={() => satelliteClick()}
            activeSatellite={preferencesContext.satelliteOnMap}
          />
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
        component: (
          <div
            className="zoom-control-container"
            style={{
              padding: "0px 16px 0px 16px",
            }}
          >
            <Zoom
              handleMinus={() => minusClick()}
              handlePlus={() => plusClick()}
            />
          </div>
        ),
      });
      if (!isDisableArrow?.isDisabled) {
        componentsOnMapArray.push({
          selectorName: ".arrow-view",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
          component: (
            <div
              className="arrow-view"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              <ViewArrow
                onClick={(directionArrow: boolean) => {
                  setDirectionArrows(directionArrow);
                }}
                isDisableArrow={isDisableArrow ?? undefined}
              />
            </div>
          ),
        });
      }
      setComponentsOnMap(componentsOnMapArray);
    }
  }, [map, isMapIdle]);

  let routesBounds: google.maps.LatLngBounds;

  if (isMapIdle && googleMap) {
    infoWindow = new googleMap.maps.InfoWindow({
      content: "",
      pixelOffset: new googleMap.maps.Size(0, -40),
    });
    routesBounds = new googleMap.maps.LatLngBounds();
  }
  //#endregion

  let componentOnMapTemp: ComponentOnMap[] = [];

  useEffect(() => {
    componentOnMapTemp = componentsOnMap.map((prev: ComponentOnMap) => {
      if (prev && prev.selectorName === ".arrow-view") {
        prev.component.props.children.props.isDisableArrow.isDisabled =
          isDisableArrow?.isDisabled;
      }
      return prev;
    });

    componentOnMapTemp.map(() => {
      if (
        !componentOnMapTemp.some(
          (el) =>
            el.selectorName === ".arrow-view" && !isDisableArrow?.isDisabled
        )
      ) {
        componentOnMapTemp.push({
          selectorName: ".arrow-view",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
          component: (
            <div
              className="arrow-view "
              style={{
                padding: "16px 16px 16px 16px",
              }}
            >
              <ViewArrow
                onClick={(directionArrow: boolean) => {
                  setDirectionArrows(directionArrow);
                }}
                isDisableArrow={isDisableArrow ?? undefined}
              />
            </div>
          ),
        });
      }
    });

    if (isDisableArrow?.isDisabled) {
      const index = componentsOnMap.findIndex((item) => {
        return _.isEqual(item.selectorName, ".arrow-view");
      });
      componentOnMapTemp.splice(index);
      setDirectionArrows(!isDisableArrow?.isDisabled);
    }

    setComponentsOnMap(componentOnMapTemp);
  }, [isDisableArrow?.isDisabled]);

  // This method is used by zoom + button for zooming map
  const plusClick = () => {
    const zoomTemp = map?.getZoom();
    if (zoomTemp) {
      map?.setZoom(zoomTemp + 1);
    }
  };

  // This method is used by zoom - button for zooming map
  const minusClick = () => {
    const zoomTemp = map?.getZoom();
    if (zoomTemp) {
      map?.setZoom(zoomTemp - 1);
    }
  };

  // this method is used to activate street map view from mapType button
  const mapClick = () => {
    map?.setMapTypeId("roadmap");
  };

  // this method is used to activate satellite view from mapType button
  const satelliteClick = () => {
    map?.setMapTypeId("hybrid");
  };

  const timestampFirstPositionString =
    infoTrack?.routeStates?.length! > 1
      ? infoTrack?.firstPosition?.gpsPositionTimestamp.toString()
      : "";
  const timestampLastPositionString =
    infoTrack?.lastPosition?.gpsPositionTimestamp.toString();
  const infoTrackLatitude = infoTrack?.firstPosition?.latitude;
  const infoTrackLongitude = infoTrack?.firstPosition?.longitude;

  function handleStatusColor(status: string) {
    switch (status) {
      case "WARNING":
        return "var(--global-colors-feedback-warning)";
      case "INFO":
        return "var(--global-colors-ui-primary)";
      case "ALARM":
        return "var(--global-colors-feedback-danger)";
      case "PARKING":
        return "var(--global-colors-ui-primary)";
      default:
    }
  }

  const renderCluster = (__namedParameters: Cluster) => {
    const clusterData = _.countBy(__namedParameters.markers, "eventType");
    if (
      Object.keys(clusterData).length === 2 &&
      Object.keys(clusterData).includes("PARKING") &&
      clusterData["PARKING"] === 1 &&
      (clusterData["WARNING"] === 1 ||
        clusterData["INFO"] === 1 ||
        clusterData["ALARM"] === 1)
    ) {
      const colorBorder = Object.keys(clusterData).find(
        (item) => item !== "PARKING"
      );
      return (
        <ClusterMarkerEvent
          data={Object.keys(clusterData).map((key) => ({
            status: colorBorder ?? "INFO",
            numbers: clusterData[key],
            color:
              handleStatusColor(
                colorBorder ?? "var(--global-colors-ui-secondary)"
              ) ?? "var(--global-colors-ui-secondary)",
          }))}
          hasChart={true}
          text={`P`}
        />
      );
    } else if (Object.keys(clusterData).length > 1) {
      return (
        <ClusterMarkerEvent
          letter="P"
          data={Object.keys(clusterData).map((key: any) => ({
            status: key ?? "INFO",
            numbers: clusterData[key],
            color:
              handleStatusColor(key) ?? "var(--global-colors-ui-secondary)",
          }))}
          hasChart={true}
          text={`${__namedParameters.markers?.length ?? ""}`}
          hasParking={Object.keys(clusterData).includes("PARKING")}
        />
      );
    } else {
      return (
        <MapMarkerEventToggle
          color={handleStatusColor(Object.keys(clusterData)[0])}
          status={Object.keys(clusterData)[0]}
          number={`${__namedParameters.markers?.length ?? ""}`}
          letter="P"
        />
      );
    }
  };

  function checkEvents(eventList: any): string[] {
    const populatedCategories: string[] = [];

    if (eventList.ALARM && eventList.ALARM.length > 0) {
      populatedCategories.push("ALARM");
    }
    if (eventList.INFO && eventList.INFO.length > 0) {
      populatedCategories.push("INFO");
    }
    if (eventList.WARNING && eventList.WARNING.length > 0) {
      populatedCategories.push("WARNING");
    }

    return populatedCategories;
  }

  const renderer = {
    render(__namedParameters: Cluster) {
      let clusterRendered: any = renderCluster(__namedParameters);

      // #region Create object for petals cluster
      let eventList: any = {
        WARNING: [],
        INFO: [],
        ALARM: [],
        PARKING: [],
      };

      const filteredMarkers = __namedParameters.markers?.filter(
        (marker: any) =>
          marker.eventInfo?.name !== "START" && marker.eventInfo?.name !== "END"
      );

      filteredMarkers?.forEach((marker: any) => {
        let obj: any = {
          name: marker.eventInfo ? marker.eventInfo.name : "PARKING",
          category: marker.eventType,
          timestamp: marker.eventInfo ? marker.eventInfo.timestamp : undefined,
        };

        if (eventList.hasOwnProperty(obj.category)) {
          eventList[obj.category].push(obj);
        } else {
          return null;
        }
      });

      // #endregion Create object for petals cluster

      return MarkerAsComponent({
        id: "customMarker-" + Math.floor(Math.random() * 100) + 1,
        googleMap: googleMap,
        lat: __namedParameters.position.lat(),
        lng: __namedParameters.position.lng(),
        map: map,
        infoWindow: infoWindow,
        show: true,
        preferences: preferencesContext,
        showTooltip: checkEvents(eventList).length > 0,
        clusterInfo: checkEvents(eventList).length > 0 ? eventList : undefined,
        component: clusterRendered,
        onClick: () => {
          map.fitBounds(__namedParameters.bounds, 100);
          infoWindow.close();
        },
        navigate: (properties) => {
          if (!!properties && properties !== "") {
            let informationsForQueryParams: any[] = properties.split("/");
            if (informationsForQueryParams.length > 0) {
              let timestamp = informationsForQueryParams[1];
              if (timestamp) {
                setTimestampFromUrl(timestamp);
              }
            }
          }
        },
      });
    },
  };

  // This method is in charge of stop markers creation
  function createMarker(properties: MarkerData) {
    return MarkerAsComponent({
      id: "customMarker-" + properties.id,
      googleMap: googleMap,
      lat: properties.latitude,
      lng: properties.longitude,
      address: properties.address,
      show: true,
      map: map,
      infoWindow: infoWindow,
      eventType: properties.eventType,
      component: properties.component,
      showTooltip: properties.showTooltip,
      routeInfo: properties.routeInfo,
      eventInfo: properties.eventInfo,

      navigate: () => {
        setTimestampFromUrl(properties.eventInfo.timestamp);
      },
      preferences: preferencesContext,
    }) as google.maps.Marker;
  }

  const addMarkerForEvent = (point: RouteState, event: RouteEvent) => {
    let eventName = event.type.name;
    const markerData = {} as MarkerData;
    markerData.id = "customMarker-" + Math.floor(Math.random() * 100) + 1;
    markerData.address = point.dynamicFields.address;
    markerData.component = (
      <MapMarkerEventLocation
        type={eventName}
        hasTooltip={false}
        preferences={preferencesContext}
      />
    );
    markerData.latitude = point?.dynamicFields?.latitude;
    markerData.longitude = point?.dynamicFields?.longitude;
    markerData.showTooltip = true;
    markerData.eventInfo = {} as EventInfo;
    markerData.eventInfo.id = infoTrack?.id ?? 0;
    markerData.eventInfo.name = eventName;
    markerData.eventInfo.speed = point.dynamicFields.speed ?? 0;
    markerData.eventInfo.timestamp = point.dynamicFields.lastUpdate.toString();
    markerData.eventType =
      eventType?.find((el) => el.name == eventName)?.severity.toUpperCase() ??
      "INFO";
    routesBounds.extend(
      new window.google.maps.LatLng(
        point.dynamicFields?.latitude,
        point.dynamicFields?.longitude
      )
    );

    markerClusterList.push(createMarker(markerData));
  };

  /**
   * This useEffect handles the creation, removal and update of the track polyline and parking marker.
   * @todo 1) Are the useRef really useful? 2) Separate Parking Marker handling from Polyline Track handling; 3) Resolve non-typed variabiles;
   */
  useEffect(() => {
    if (!(!!map && isMapIdle)) return;

    if (
      _.isEqual(prevInfoTrack, infoTrack) &&
      _.isEqual(selectedEvents, prevSelectedEvents)
    ) {
      return;
    } else {
      if (infoTrack) {
        setPrevInfoTrack(infoTrack);
      }
      if (selectedEvents) {
        setPrevSelectedEvents(selectedEvents);
      }
    }
    if (stopMarker.current) {
      stopMarker.current?.onRemove();
    }
    if (openStopDetails) {
      if (!!infoTrack && infoTrackLatitude && infoTrackLongitude) {
        stopMarker.current = MarkerAsComponent({
          id: "stopMarker",
          googleMap: googleMap,
          lat: infoTrackLatitude,
          lng: infoTrackLongitude,
          map: map,
          preferences: preferencesContext,
          component: (
            <MapMarkerLocation
              type={infoTrack.routeStates.length > 1 ? "PARKING" : "POSITION"}
            />
          ),
        });
      }
    }

    // draw path
    if (
      !infoTrack ||
      !infoTrack.routeStates ||
      infoTrack.routeStates.length === 0
    )
      // nothing to draw -> do nothing
      return;

    // TODO: color must be removed from data, it should come from event type/category
    // enrich data with color info
    let gpsData: RouteState[] = infoTrack.routeStates
      .filter(
        (x, index) =>
          x.routeStateType === "TRACK" ||
          (x.routeStateType === "STOP" && index === 0)
      )
      .map((item) => {
        if (item.events && item.events.length > 0) {
          return {
            ...item,
            events: item.events.map((el) => {
              switch (el?.type?.name) {
                case "SPEED_LIMIT":
                  return { ...el, color: "#FF4F48" };
                case "DRIVER_CHANGED":
                case "DRIVER_IDENTIFIED":
                  return { ...el, color: "#00FFFF" };
                default:
                  return { ...el, color: "#0000FF" };
              }
            }),
          };
        } else return { ...item };
      });

    if (infoTrack.routeStateType === "TRACK") {
      if (
        !!gpsData &&
        !!prevCoordsRef.current &&
        _.isEqual(gpsData, prevCoordsRef.current)
      )
        return;
      // same polyline -> do nothing

      // new polyline -> remove previous (if exists) and draw new one

      if (!!polyRef.current) {
        polyRef?.current?.onRemove();
        if (arrows.length > 0) {
          arrows.forEach((arrow: any) => {
            arrow.setMap(null);
          });
          setArrows([]);
        }
        if (summaryMarkers) {
          markerClusterList = [];
          summaryMarkers.onRemove();
          setSummaryMarkers(undefined);
        }
        if (clusterArrow.length > 0) {
          clusterArrow.forEach((cluster: any) => {
            cluster.onRemove();
          });
          setClusterArrow([]);
        }
        polyRef.current = null;
        prevCoordsRef.current = null;
        setPolylineCreated(false);
        setValue(0);
      }

      polyRef.current = ClassPolyline({
        googleMap: googleMap,
        map: map,
        infoWindow: infoWindow,
        batchPolyLine: gpsData,
        initialCurrentPoint: {
          start: 0,
          stop: gpsData.length - 1,
          indexPoint: 0,
        },
        viewPointMarkers: viewPolylineMarkers,
        viewEventMarkers: true,
        polylineMarkerStart: <MapMarkerLocation type={"START"} />,
        polylineMarkerEnd: <MapMarkerLocation type={"END"} />,
      });
      prevCoordsRef.current = gpsData;
      setPolylineCreated(true);

      //Region marker events
      const excludedEventNames = [
        "START",
        "IGNITION_KEY_ON",
        "END",
        "IGNITION_KEY_OFF",
      ];

      infoTrack.routeStates.forEach((point, j) => {
        const currPoint = point;

        if (currPoint && !_.isEmpty(currPoint.events)) {
          currPoint.events.forEach((event) => {
            if (!excludedEventNames.includes(event.type.name)) {
              if (event.type.name === "SPEED_LIMIT") {
                const prevEvent = infoTrack.routeStates[j - 1];
                const nextEvent = infoTrack.routeStates[j + 1];

                const addMarker = () => {
                  addMarkerForEvent(currPoint, event);
                };

                if (
                  (prevEvent != null && _.isEmpty(prevEvent.events)) ||
                  prevEvent.events.some(
                    (event) => event.type.name !== "SPEED_LIMIT"
                  )
                ) {
                  addMarker();
                } else if (
                  (nextEvent != null && _.isEmpty(nextEvent.events)) ||
                  nextEvent?.events.some(
                    (event) => event.type.name !== "SPEED_LIMIT"
                  )
                ) {
                  addMarker();
                } else {
                  return;
                }
              } else {
                addMarkerForEvent(currPoint, event);
              }
            }
          });
        }
      });

      setSummaryMarkers(
        new MarkerClusterer({
          map: map,
          markers: markerClusterList as google.maps.Marker[],
          renderer: renderer,
        })
      );
    } else {
      if (summaryMarkers) {
        summaryMarkers.onRemove();
        setSummaryMarkers(undefined);
      }
      if (arrows.length > 0) {
        arrows.forEach((arrow: any) => {
          arrow.setMap(null);
        });
        setArrows([]);
      }
      if (clusterArrow.length > 0) {
        clusterArrow.forEach((cluster: any) => {
          cluster.onRemove();
        });
        setClusterArrow([]);
      }
    }

    // map zoom
    const bounds = new google.maps.LatLngBounds();
    if (gpsData) {
      gpsData.forEach((point: any) => {
        bounds.extend(
          new window.google.maps.LatLng(
            point.dynamicFields.latitude,
            point.dynamicFields.longitude
          )
        );
      });
      map.fitBounds(bounds, { top: 150, bottom: 350, right: 150, left: 150 });
    }
  }, [selectedEvents, infoTrack, map, isMapIdle]);

  useEffect(() => {
    const _arrows: google.maps.Marker[] = [];
    let clusterList: any[] = [];

    if (directionArrow && infoTrack) {
      infoTrack.routeStates.forEach((showPoint: RouteState) => {
        const arrow = MarkerAsComponent({
          id: "customMarker-" + Math.floor(Math.random() * 100) + 1,
          googleMap: googleMap,
          lat: showPoint.dynamicFields.latitude,
          lng: showPoint.dynamicFields.longitude,
          address: showPoint.dynamicFields.address,
          show: true,
          map: map,
          infoWindow: infoWindow,
          component: (
            <MapMarkerLocator rotate={showPoint.dynamicFields.direction} />
          ),
          showTooltip: true,
          preferences: preferencesContext,
          directionArrow: showPoint.dynamicFields.direction,
          arrowInfo: {
            address: showPoint.dynamicFields.address,
            date: showPoint.dynamicFields.lastUpdate.toString(),
            speed: showPoint.dynamicFields.speed,
          },

          onClick: () => {
            const timestamp = showPoint?.dynamicFields?.lastUpdate;

            if (timestamp) {
              setTimestampFromUrl(timestamp);
            }
          },
        }) as google.maps.Marker;

        _arrows.push(arrow);
      });
      setArrows(_arrows);
    } else {
      if (arrows.length > 0) {
        arrows.forEach((arrow: any) => {
          arrow.setMap(null);
        });
        setArrows([]);
      }
      if (clusterArrow.length > 0) {
        clusterArrow.forEach((cluster: any) => {
          cluster.onRemove();
        });
        setClusterArrow([]);
      }
    }

    if (_arrows.length > 0 && map) {
      const rendererArrow = {
        render(__namedParameters: Cluster) {
          let firstMarker: any = null;
          if (
            __namedParameters.markers &&
            __namedParameters.markers.length > 0
          ) {
            firstMarker = __namedParameters.markers[0];
            if (firstMarker) {
              firstMarker.setMap(map);
            }
          }
          return MarkerAsComponent({
            id: "markerCluster",
            googleMap: googleMap,
            lat: __namedParameters.position.lat(),
            lng: __namedParameters.position.lng(),
            hasArrowCluster: true,
            map: map,
            onClick: () => {
              map.fitBounds(__namedParameters.bounds, 100);
            },
            preferences: preferencesContext,
            component: (
              <MapMarkerLocator
                rotate={parseInt(firstMarker?.directionArrow)}
              />
            ),
          });
        },
      };

      let cluster: any;

      cluster = new MarkerClusterer({
        map: map,
        markers: _arrows,
        renderer: rendererArrow,
      });

      clusterList.push(cluster);
    }
    setClusterArrow(clusterList);
  }, [directionArrow, infoTrack?.id]);

  useEffect(() => {
    if (!openTrackDetails && !!polyRef.current) {
      polyRef.current.onRemove();
      polyRef?.current?.onRemoveArrows();
      if (polyRef?.current?.clusterArrow.length > 0) {
        polyRef?.current?.clusterArrow.forEach((cluster: any) => {
          cluster.onRemove();
        });
      }
      polyRef.current = null;
      prevCoordsRef.current = null;
      setPolylineCreated(false);
      setValue(0);
    }
  }, [openTrackDetails]);

  /**
   * This useEffect moves the cursor and colors the polyline based
   * on the value received from the track bar.
   */
  useEffect(() => {
    polylineCreated &&
      polyRef.current &&
      polyRef.current.settingIncrementalPolyline({
        start: 0,
        stop: (prevCoordsRef.current?.length || 1) - 1,
        indexPoint: value,
      });
  }, [value, polylineCreated]);

  useEffect(() => {
    if (polylineCreated && !!polyRef.current) {
      viewPolylineMarkers
        ? polyRef.current.onShowMarker()
        : polyRef.current.onHideMarker();
    }
  }, [viewPolylineMarkers, polylineCreated]);

  const fuelsConsumption = useMemo(() => {
    if (summaryTrack?.fuelsConsumption) {
      let cumulative = 0;
      const fuelsConsumptionModified = summaryTrack.fuelsConsumption.map(
        (value) => {
          cumulative += value.data;
          return { data: cumulative, date: value.date };
        }
      );
      return fuelsConsumptionModified;
    }
    return [];
  }, [summaryTrack]);

  function eventCount(eventName: string, events: RouteEvent[]) {
    let counter = 0;
    events.forEach((event) => {
      event.type.name === eventName && counter++;
    }, []);
    return counter;
  }

  function handleEvents(events: RouteEvent[]): RouteEventReduced[] {
    return events
      .filter(
        (event) => event.type.name !== "START" && event.type.name !== "END"
      )
      .map((event) => {
        return {
          event: event,
          count: eventCount(event.type.name, events),
        };
      });
  }
  const areThereRouteStates =
    !!infoTrack && infoTrack?.routeStates && infoTrack.routeStates.length > 0;

  useEffect(() => {
    if (!_.isEqual(infoTrack, prevInfoTrackForTrackBar)) {
      setPrevInfoTrackForTrackBar(infoTrack);
    }
  }, [infoTrack]);

  const isStopRoute = !!infoTrack && infoTrack?.routeStateType === "STOP";

  const areThereEvents =
    areThereRouteStates &&
    infoTrack?.routeStates[value] &&
    infoTrack?.routeStates[value].events.length !== 0;

  // #region Geofence and Action Menu methods
  // marker cluster for geofences
  const geofenceRenderer = {
    render(__namedParameters: Cluster) {
      const clusterData = _.countBy(__namedParameters.markers, "status");
      return MarkerAsComponent({
        id: "geofenceCluster",
        googleMap: googleMap,
        preferences: preferencesContext,
        lat: __namedParameters.position.lat(),
        lng: __namedParameters.position.lng(),
        map: map,
        onClick: () => {
          map.fitBounds(__namedParameters.bounds, 100);
        },
        component: (
          <ClusterMarker
            data={Object.keys(clusterData).map((key) => ({
              status: key ?? "UNKNOWN",
              numbers: clusterData[key],
              color:
                geofenceCategories.find(
                  (item: GeofenceCategory) => item.name === key
                )?.color ?? "#0052BD",
            }))}
            hasChart={true}
            text={`${__namedParameters.markers?.length ?? ""}`}
          />
        ),
      });
    },
  };

  // This methods are used by action component for fullScreen button
  function isFullscreen(element: any) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  function requestFullscreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const fullsc = () => {
    const elementToSendFullscreen = map?.getDiv().firstChild;
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  };

  // This method show geofence markers, creating cluster for nearby markers
  // This method must be sure that all markers and clusters being removed from map
  const removeGeofenceMarkers = () => {
    if (geofenceMarkers.length !== 0) {
      geofenceMarkers.forEach((geofence: any) => geofence.setMap(null));
    }
    if (geofenceArea) {
      geofenceArea.setMap(null);
    }
    if (!_.isEmpty(geofenceClusterMarkers)) {
      geofenceClusterMarkers.setMap(null);
      geofenceClusterMarkers = {} as MarkerClusterer;
    }
    markerClusterList = [];
    geofenceMarkers = [];
  };
  function setGeofences(geofenceOptions: any) {
    removeGeofenceMarkers();
    let markerArray: google.maps.Marker[] = [];
    let bounds = new googleMap.maps.LatLngBounds();
    geofenceOptions.forEach((option: any) => {
      if (option.checked) {
        option.geofences.forEach((geofence: any) => {
          const category = geofenceCategoriesSelectors.selectById(
            store.getState(),
            geofence.geofenceCategory
          );
          markerArray.push(
            MarkerAsComponent({
              id: "customMarker-" + geofence?.id,
              googleMap: googleMap,
              preferences: preferencesContext,
              lat: geofence?.shape?.center.lat,
              lng: geofence?.shape?.center.lng,
              map: map,
              geofence: geofence.name,
              isGeofence: true,
              onClick: () => clickOnGeofence(geofence, option.color),
              status: category?.name,
              component: (
                <>
                  <MapMarkerPin
                    key={geofence.id}
                    color={category?.color ?? "#0052BD"}
                  />
                  {geofence.name && (
                    <label className="labelId">{geofence.name}</label>
                  )}
                </>
              ),
            })
          );
          bounds.extend(
            new googleMap.maps.LatLng(
              geofence.shape.center.lat,
              geofence.shape.center.lng
            )
          );
        });
      }
    });

    geofenceMarkers.push(...markerArray);
    geofenceClusterMarkers = new MarkerClusterer({
      map: map,
      markers: geofenceMarkers,
      renderer: geofenceRenderer,
    });
  }

  // This method show the geofence area after user click on geofence marker
  function clickOnGeofence(geofence: any, color: string) {
    if (geofenceArea) geofenceArea.setMap(null);
    map.panTo(geofence.shape.center);

    if (geofence.geofenceShapeEnum === "CIRCLE") {
      geofenceArea = new googleMap.maps.Circle({
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.3,
        map,
        center: geofence.shape.center,
        radius: geofence.shape.radius,
      });

      map.fitBounds(geofenceArea.getBounds(), 100);
    } else {
      if (geofence.geofenceShapeEnum === "POLYGON") {
        geofenceArea = new googleMap.maps.Polygon({
          paths: geofence.shape.points,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
          map,
        });
      }
      let bounds = new googleMap.maps.LatLngBounds();
      geofence.shape.points.forEach((point: any) => {
        bounds.extend(new googleMap.maps.LatLng(point.lat, point.lng));
      });
      map.fitBounds(bounds, 100);
    }
  }

  // This use effect updates components on map on geofences data changes

  useEffect(() => {
    if (!_.isEmpty(geofences) && !_.isEmpty(geofenceCategories)) {
      setComponentsOnMap((prev: ComponentOnMap[]) => {
        prev.forEach((item: any) => {
          if (item.selectorName === ".action-control") {
            item.component.props.children.props.geofencesProp.geofences =
              geofences;
            item.component.props.children.props.geofencesProp.geofenceCategories =
              geofenceCategories;
          }
        });
        return prev;
      });
    }
  }, [geofences, geofenceCategories]);

  useEffect(() => {
    // Create event listeners for map drag and zoom events
    let dragListener: any;
    let zoomListener: any;
    if (googleMap && map) {
      dragListener = googleMap.maps.event.addListener(
        map,
        "dragend",
        handleMapChange
      );
      zoomListener = googleMap.maps.event.addListener(
        map,
        "zoom_changed",
        handleMapChange
      );
    }

    // Cleanup function to remove event listeners
    return () => {
      if (googleMap) {
        googleMap.maps.event.removeListener(dragListener);
        googleMap.maps.event.removeListener(zoomListener);
      }
    };
  }, [map, geofenceMarkers]);

  function handleMapChange() {
    updateMapData();
  }

  const updateMapData = useCallback(() => {
    const visibleMarkers: any[] = []; // Array of visible markers

    if (geofenceMarkers.length > 0) {
      geofenceMarkers.forEach((marker: any) => {
        if (map.getBounds().contains(marker.getPosition())) {
          visibleMarkers.push(marker); // Add marker to array of visible markers
          if (!marker.getMap()) {
            marker.setMap(map);
          }
        } else {
          marker.setMap(null);
        }
      });

      if (geofenceClusterMarkers) {
        geofenceClusterMarkers.clearMarkers(); // Remove marker from cluster
        geofenceClusterMarkers.addMarkers(visibleMarkers); // Add markers to cluster
      }
    }
  }, [map, geofenceMarkers, geofenceClusterMarkers]);

  // #endregion

  return (
    <>
      <BaseMap
        id={id}
        hasStreetViewUp={true}
        googleMapsApiKey={googleMapsApiKey}
        hasStreetView={true}
        zoom={zoom}
        latitude={latitude}
        longitude={longitude}
        getMap={setMap}
        getIsMapIdle={setIsMapIdle}
        showMarkers={true}
        isArrowsDisabled={isDisableArrow?.isDisabled}
        setStreetViewEnabled={setStreetViewEnabled}
        exitFromStreetView={!_.isEqual(infoTrack, prevInfoTrackForTrackBar)}
        removeComponents={isDisableArrow ? ["arrow-view"] : []}
      >
        {componentsOnMap}
      </BaseMap>

      <div
        className="vlh-open-details"
        style={
          expanded
            ? {
                top: "156px",
              }
            : {
                top: "76px",
              }
        }
      >
        <div className="vlh-open-details-inner">
          {openTrackDetails && !streetViewEnabled && !!summaryTrack && (
            <div className="track-details-up">
              <TrackDetails
                open={openTrackOrStop}
                details={{
                  totalKm: summaryTrack?.traveled,
                  cost: summaryTrack?.costs,
                  avgSpeed: summaryTrack?.avgSpeed,
                  maxSpeed: summaryTrack?.maxSpeed,
                  trackTime:
                    summaryTrack?.parkingTime || summaryTrack?.drivingTime
                      ? (summaryTrack?.parkingTime ?? 0) +
                        (summaryTrack?.drivingTime ?? 0)
                      : undefined,
                  fuelCons: summaryTrack?.consumption,
                  fuelDatas: {
                    data:
                      fuelsConsumption && fuelsConsumption?.length > 0
                        ? fuelsConsumption?.map(
                            (fuelConsumption) => fuelConsumption.data
                          )
                        : [0, 0],
                  },
                  fuelDates:
                    fuelsConsumption && fuelsConsumption?.length > 0
                      ? fuelsConsumption?.map(
                          (fuelConsumption) => fuelConsumption.date
                        )
                      : [
                          dayjs(
                            new Date().setDate(new Date().getHours() - 2)
                          ).format("YYYY/MM/DD HH:mm"),
                          dayjs(
                            new Date().setDate(new Date().getHours() - 1)
                          ).format("YYYY/MM/DD HH:mm"),
                        ],
                }}
                preference={preferencesContext}
              />
            </div>
          )}

          {openStopDetails &&
            !streetViewEnabled &&
            isStopRoute &&
            infoTrackLatitude &&
            infoTrackLongitude && (
              <div
                className="stop-details-out"
                style={{ position: "absolute" }}
              >
                <StopDetails
                  open={openTrackOrStop}
                  title={
                    infoTrack?.routeStates?.length! > 1
                      ? t("locationHistory.stopDetails.title")
                      : t("locationHistory.onlyStopDetails.title")
                  }
                  address={infoTrack?.lastPosition.address ?? t("common.na")}
                  coords={{
                    lat: infoTrackLatitude,
                    long: infoTrackLongitude,
                  }}
                  details={{
                    start: timestampFirstPositionString ?? t("common.na"),
                    end: timestampLastPositionString ?? t("common.na"),
                    total:
                      timestampFirstPositionString &&
                      timestampLastPositionString
                        ? formatTotalTime(
                            timestampFirstPositionString ?? "",
                            timestampLastPositionString ?? ""
                          )
                        : "",
                  }}
                />
              </div>
            )}
          <div className="track-details-down">
            {openTrackBar &&
              !streetViewEnabled &&
              areThereRouteStates &&
              infoTrack?.routeStates?.length! > 1 && (
                <>
                  {areThereEvents &&
                    infoTrack?.routeStates[value].events.filter(
                      (event) =>
                        event.type.name !== "START" && event.type.name !== "END"
                    ) && (
                      <div
                        style={
                          trackBarCollapsed
                            ? { float: "left", marginTop: "-65px" }
                            : { float: "left", marginTop: "-46px" }
                        }
                      >
                        <EventDetail
                          events={handleEvents(
                            infoTrack?.routeStates[value].events
                          )}
                        />
                      </div>
                    )}
                  <div>
                    {infoTrack &&
                      prevInfoTrackForTrackBar &&
                      !streetViewEnabled &&
                      !_.isEmpty(infoTrack) && (
                        <DetailsTrackBar
                          data={
                            !_.isEqual(infoTrack, prevInfoTrackForTrackBar)
                              ? infoTrack
                              : prevInfoTrackForTrackBar
                          }
                          sendPosition={setValue}
                          getCollapsed={setTrackBarCollapsed}
                          sendPositionTimestamp={(
                            positionTimestamp: string
                          ) => {
                            !!sendCurrentTrackBarPosition &&
                              sendCurrentTrackBarPosition(positionTimestamp);
                          }}
                          defaultPosition={
                            timestampFromUrl
                              ? timestampFromUrl
                              : currentRouteStateTimestamp
                          }
                          drivers={drivers}
                        />
                      )}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
