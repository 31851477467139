import { EntityId } from "@reduxjs/toolkit";
import i18next from "i18next";
import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { Frame } from "stompjs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import ContentSidebar from "../../layout/ContentSidebar";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import {
  VehicleBox,
  Vehicle as VehicleBoxType,
} from "../../ui/FleetControl/VehicleBox";
import { VehicleBoxGroupButton } from "../../ui/FleetControl/VehicleBoxGroupButton";
import VehicleDetailsModal, {
  Asset as AssetPropType,
  Driver as DriverPropType,
  DriverStatus as DriverStatusPropType,
  FleetView as FleetPropType,
  Vehicle as VehiclePropType,
  VehicleStatus as VehicleStatusPropType,
} from "../../ui/FleetControl/VehicleDetailsModal";
import { IconClose } from "../../ui/Icon/Line/Close";
import { Sidebar } from "../../ui/Navigation/Sidebar";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getCity,
  getIllustrationFromVehicleType,
  getProvince,
  getQueryString,
} from "../../utils/Utils";
import { Asset, assetsSelectors } from "../asset/assetsSlice";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusEmptyState,
  driversStatusSelectors,
  getDriverStatusAsync,
  upsertDriverStatus,
} from "../driver/driversStatusSlice";
import {
  Geofence,
  geofencesEmptyState,
  geofencesSelectors,
  getGeofencesAsync,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesEmptyState,
  geofenceCategoriesSelectors,
} from "../geofenceCategory/geofenceCategoriesSlice";
import {
  PublicStopETA,
  PublicStopStatus,
  PublicTransportStatus,
  getPublicTransportAsync,
  publicTransportStatusSelectors,
  upsertPublicTransportStatus,
} from "../publicTransport/status/publicTransportDocumentStatusSlice";
import {
  Preferences,
  updatePreferencesAsync,
} from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import {
  FleetStatusCounts,
  FleetVehicleStatusSummary,
  VehicleStatusCounts,
  getVehicleStatusAmountAsync,
  vehicleStatusAmountSelectors,
} from "../vehicle/vehiclesStatusAmountSlice";
import {
  StatusVehicleType,
  VehicleStatus,
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  upsertVehicleWithDelta,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import {
  getVehiclesViewAsync,
  selectVehiclesStatusViewSliceLastUpdate,
  selectVehiclesStatusViewSliceStatus,
  vehiclesStatusViewEmptyState,
  vehiclesStatusViewSelectors,
} from "../vehicle/vehiclesStatusViewSlice";
import {
  VehicleView,
  vehiclesViewEmptyState,
  vehiclesViewSelectors,
} from "../vehicle/vehiclesViewSlice";
import "./FleetControl.css";
import { FleetControlFilterBar } from "./FleetControlFilterBar";
import { FleetControlMap } from "./FleetControlMap";
import { FleetPolling } from "./FleetPolling";
import { LiveTrackingMap } from "./LiveTrackingMap";
import { FleetView, fleetViewsSelectors } from "./fleetViewsSlice";

let stompClient: any;
let vehiclesPolling: any;
let fetchVehicles: () => any;
let fetchVehicle: (vehicleId: number, fleetId: number) => any;
let fetchPublicTransport: (publicTransportId: number, fleetId: number) => any;

interface PointObj {
  latitude: number;
  longitude: number;
  direction: number;
}

interface VehicleStatusAmount {
  status: StatusVehicleType;
  vehicles: number;
}
interface FleetControlProps {
  permissions: UserPermissions;
}

const FleetControl: React.FC<FleetControlProps> = ({ permissions }) => {
  //#region Declarations
  const { t } = useTranslation();

  const [queryParams, setQueryParams] = useState<string>(
    getQueryString({
      vehicleStatus: ["MOVING", "PARKING", "STOP", "OFFLINE"],
      sort: "dynamicFields.lastUpdate,DESC",
    })
  );

  const [currentActive, setCurrentActive] = useState(-1);
  const [isWebSocketConnecting, setIsWebSocketConnecting] =
    useState<boolean>(false);

  const [expanded, setExpanded] = useState(true);
  const [hidden, setHidden] = useState("");
  const [enableTracking, setEnableTracking] = useState(false);
  const [urlReloadTriggered, setUrlReloadTriggered] = useState(false);
  const [trackingData, setTrackingData] = useState<PointObj[]>([]);
  const [currentPoint, setCurrentPoint] = useState<PointObj>();
  const [streetViewEnabled, setStreetViewEnabled] = useState(false);
  const [vehicleStatusFromBadge, setVehicleStatusFromBadge] = useState(
    {} as VehicleStatusCounts
  );
  const [vehiclesSearchKey, setVehiclesSearchKey] = useState("");
  const [polling, setPolling] = useState({} as FleetPolling);

  const gtfs = permissions.publicTransport.read === true;

  const fleetViews: FleetView[] = useAppSelector((state: any) =>
    fleetViewsSelectors.selectAll(state)
  );
  let vehicles: VehicleView[] = useAppSelector((state: any) =>
    vehiclesViewSelectors.selectAll(state)
  );

  let drivers: Driver[] = useAppSelector((state: any) =>
    driversSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector(
    (state: any) =>
      vehiclesStatusViewSelectors.selectAll(state) as VehicleStatus[]
  );

  let vehiclesStatusIds: EntityId[] = useAppSelector((state: any) =>
    vehiclesStatusViewSelectors.selectIds(state)
  );

  const tenantIdFromLocalStorage = localStorage.getItem("tenantId");
  let vehicleStatusAmount: FleetVehicleStatusSummary =
    useAppSelector((state: any) =>
      vehicleStatusAmountSelectors.selectById(
        state,
        tenantIdFromLocalStorage != null ? Number(tenantIdFromLocalStorage) : -1
      )
    ) ?? ({} as FleetVehicleStatusSummary);

  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );

  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );
  const lastUpdatePolling = useAppSelector(
    selectVehiclesStatusViewSliceLastUpdate
  );
  const vehiclesStatusViewSliceStatus = useAppSelector(
    selectVehiclesStatusViewSliceStatus
  );
  const dispatch = useAppDispatch();

  let infoVehicle: Vehicle =
    useAppSelector((state: any) =>
      vehiclesSelectors.selectById(state, currentActive)
    ) ?? ({} as Vehicle);

  let selectedVehicleStatus: VehicleStatus =
    useAppSelector((state: any) =>
      vehiclesStatusSelectors.selectById(state, infoVehicle.vehicleStatus)
    ) ?? ({} as VehicleStatus);

  const [listGroup, setListGroup] = useState(
    [] as {
      name: string;
      vehicles: Vehicle[];
    }[]
  );

  let assets: Asset[] = useAppSelector(
    (state: any) =>
      infoVehicle?.asset?.map(
        (assetId: number) =>
          assetsSelectors.selectById(state, assetId) ??
          ({ id: assetId } as Asset)
      ) ?? ([] as Asset[])
  );

  let driver: Driver = useAppSelector(
    (state: any) =>
      driversSelectors.selectById(state, infoVehicle.driver) ?? ({} as Driver)
  );

  let driverStatus: DriverStatus = useAppSelector(
    (state: any) =>
      driversStatusSelectors.selectById(state, infoVehicle.driver) ??
      ({} as DriverStatus)
  );

  let fleetView: FleetView = useAppSelector(
    (state: any) =>
      fleetViewsSelectors.selectById(state, infoVehicle.fleet) ??
      ({} as FleetView)
  );

  let selectedPublicTransportStatus: PublicTransportStatus = useAppSelector(
    (state: any) =>
      publicTransportStatusSelectors.selectById(state, infoVehicle.id) ??
      ({} as PublicTransportStatus)
  );

  const [preferencesContext, setPreferencesContext]: [
    Preferences,
    Dispatch<SetStateAction<Preferences>>
  ] = useContext(UserContext);

  const navigate = useNavigate();

  const [latitude, setLatitude] = useState(41.9109);
  const [longitude, setLongitude] = useState(12.4818);
  const [zoom, setZoom] = useState(3);

  //#endregion Declarations

  // #region FiFo_stack

  /**** FiFo Stack implementation *************
   * Once available, messages from websocket are pushed to a FiFo Stack
   * to let each point be processed with the correct timings (consider
   * camera animation duration). This mechanism is mandatory when several
   * messages come from the websocket at the same time. processWebsocketMessage
   * function is in charge of processing the available messages in the stack at
   * specified intervals. This mechanism introduces a small delay in
   * messages handling. At the same time, it avoids race conditions and lets
   * camera animation be smooth as needed.
   * ****************************************/
  let fifoStack: any[] = [];
  let stackProcessintervalId: any = 0;
  let currentTrackingVehicleId: number = 0;
  /**
   * Add message to the fifo stack
   * @param {*} message
   */
  function updateTrackingPoints(message: Frame) {
    const parsedJson = JSON.parse(JSON.parse(message.body).data);
    let vehicleId = parsedJson.dynamicFields?.vehicleId;
    let vehicleIdPT = parsedJson?.id;
    if (vehicleId === currentTrackingVehicleId) {
      fifoStack.push(parsedJson);
      //TODO: soluzione momentanea: aggiungere nel public transport document il campo vehicleId
    } else if (vehicleIdPT === currentTrackingVehicleId) {
      fifoStack.push(parsedJson);
    }
  }

  /**
   * Remove the oldest message from the fifo stack
   */
  function processWebsocketMessage() {
    if (fifoStack.length > 0) {
      let currentMessage = fifoStack.shift();
      setInfoFromWebsocketMessage(currentMessage);
    }
  }

  /**
   * Activate/Deactivate tick function to process messages at specified intervals
   * @param {*} enabled
   * @returns
   */
  function toggleProcessWsStack(enabled: boolean) {
    if (enabled) {
      if (stackProcessintervalId !== 0)
        // already enabled
        return;
      // check the fifo stack every 2 seconds
      stackProcessintervalId = setInterval(processWebsocketMessage, 2000);
    } else {
      if (stackProcessintervalId !== 0) {
        clearInterval(stackProcessintervalId);
        stackProcessintervalId = 0;
      }
    }
  }
  // #endregion FiFo_stack

  // #region WebSocket
  /**
   * Handle data coming from the WebSocket
   * @param {*} message
   * @returns
   */
  async function setInfoFromWebsocketMessage(parsedJson: any) {
    try {
      let vehicleStatus: VehicleStatus = parsedJson as VehicleStatus;
      let driverStatus: DriverStatus = parsedJson as DriverStatus;
      let publicTransportStatus: PublicTransportStatus =
        parsedJson as PublicTransportStatus;
      if (vehicleStatus?.deviceStatus) {
        if (vehicleStatus.dynamicFields?.ignitionKey) {
          setCurrentPoint({
            latitude: vehicleStatus?.dynamicFields?.latitude ?? 0,
            longitude: vehicleStatus?.dynamicFields?.longitude ?? 0,
            direction: vehicleStatus?.dynamicFields?.direction ?? 0,
          });

          store.dispatch(upsertVehicleWithDelta(vehicleStatus));
        }
      }
      if (driverStatus?.driverStatus) {
        dispatch(upsertDriverStatus(driverStatus));
      }
      if (publicTransportStatus?.stopCode) {
        store.dispatch(upsertPublicTransportStatus(publicTransportStatus));
      }
    } catch (err) {
      console.log("Unable to fetch data from websocket: " + err);
    }
  }

  /**
   * Set the connection with the WebSocket
   * @param {*} toggle
   * @param {*} id
   */
  async function webSocketToggleConnect(toggle: boolean, id: number) {
    currentTrackingVehicleId = id;
    setIsWebSocketConnecting(true);
    if (toggle) {
      try {
        let socket = new SockJS(
          process.env.REACT_APP_WEBSOCKET_SERVER_URL +
            "/websocket/gtfleetwebsocket"
        );
        stompClient = Stomp.over(socket);
        stompClient.connect(
          { "X-Authorization": "Bearer " + localStorage.getItem("jwtToken") },
          () => {
            console.log("Web Socket is connected!");
            stompClient.subscribe(
              "/users/queue/updates",
              updateTrackingPoints,
              { "device-list": "1,3,5" }
            );
            setIsWebSocketConnecting(false);
            toggleProcessWsStack(true);
          },
          (err: any) => {
            setIsWebSocketConnecting(false);
            console.log("Web socket connection error: " + err);
            if (err.command === "ERROR") {
              ToastNotification({
                toastId: "websocketError",
                status: "error",
                description: i18next.t("common.websocketError"),
              });
            }
          }
        );
      } catch (error: any) {
        setIsWebSocketConnecting(false);
        console.log("Unable to refresh token (expired?): " + error);
        // toast notification for error
        ToastNotification({
          toastId: "tokenError",
          status: "error",
          description: i18next.t("common.tokenError"),
        });
      }
    } else {
      toggleProcessWsStack(false);
      if (stompClient != null) {
        await stompClient.disconnect(() => {
          setIsWebSocketConnecting(false);
          console.log("Web Socket is disconnected!");
        });
      }
    }
  }
  // #endregion WebSocket

  // #region Vehicles

  /**
   * Define a function for the polling of a single selected vehicle
   */
  fetchVehicle = (vehicleId: number, fleetId: number) => {
    console.log("Loaded vehicle id:" + vehicleId + " data from API");
    dispatch(getVehicleAsync({ fleetId: fleetId, id: vehicleId }));
  };

  /**
   * Define a function for the polling of a list of vehicles
   */
  fetchVehicles = () => {
    store.dispatch(getVehicleStatusAmountAsync());
    dispatch(getVehiclesViewAsync(queryParams)).then(() => {
      if (currentActive && infoVehicle.id && infoVehicle.fleet) {
        fetchVehicle(infoVehicle.id, infoVehicle.fleet);
        if (gtfs) {
          fetchPublicTransport(infoVehicle.id, infoVehicle.fleet);
        }
      }
    });
    vehiclesPolling = setTimeout(() => fetchVehicles(), 60000);
  };

  /**
   * Transforms a list of vehicles into a map of {Fleet -> Vehicles}
   * every time the list of vehicles changes.
   */
  useEffect(() => {
    if (vehicles.length > 0 && fleetViews.length > 0) {
      let groupNames: {
        name: string;
        vehicles: Vehicle[];
      }[] = [];

      vehiclesStatusIds.forEach((vehicleStatusId) => {
        const vehicle: Vehicle =
          (vehiclesViewSelectors.selectById(
            store.getState(),
            vehicleStatusId.valueOf()
          ) as Vehicle) ?? ({} as Vehicle);
        const fleetName = fleetViews.find((x) => x.id === vehicle.fleet)?.name;
        if (fleetName) {
          if (
            groupNames.filter(function (e) {
              return e.name === fleetName;
            }).length === 0
          ) {
            groupNames.push({
              name: fleetName ?? t("common.na"),
              vehicles: [vehicle],
            });
          } else {
            let indexGroupNames = groupNames.findIndex((value) => {
              return (
                value.name ===
                fleetViews.find((x) => x.id === vehicle.fleet)?.name
              );
            });
            groupNames[indexGroupNames].vehicles.push(vehicle);
          }
        }
      });
      if (groupNames.length > 0) {
        groupNames = _.sortBy(groupNames, (x) => x.name);
      }

      // #region Adds fleet without vehicles
      const params = new URLSearchParams(queryParams);
      const fleetNames = params.getAll("fleet.name");

      let emptyFleet: FleetView[] = fleetViews.filter(
        (el) => el.vehiclesSize == 0
      );
      const containsFleetName: boolean = emptyFleet.some((el) =>
        fleetNames.includes(el.name)
      );

      if (
        !_.isEmpty(emptyFleet) &&
        (_.isEmpty(fleetNames) || containsFleetName)
      ) {
        emptyFleet.forEach((fleet: FleetView) => {
          groupNames.push({ name: fleet.name, vehicles: [] });
        });
      }
      // #endregion Adds fleet without vehicles

      setListGroup([...groupNames]);
    } else {
      // #region Shows the fleet after filtering for it, even if it has no vehicles
      if (!!queryParams && queryParams.includes("fleet.name")) {
        const params = new URLSearchParams(queryParams);
        const fleetName = params.get("fleet.name") || "";
        console.log(queryParams);

        setListGroup([{ name: fleetName, vehicles: [] }]);
      }
      // #endregion Shows the fleet after filtering for it, even if it has no vehicles
    }
  }, [vehicles, t, vehiclesStatusIds, fleetViews]);

  /**
   * Initialization UseEffect with cleaning instructions on unmount.
   */
  useEffect(() => {
    document.title = t("navigation.userMenu.fleetControl");
    fetchVehicles();
    if (permissions.geofences.read) {
      store.dispatch(
        getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
      );
    }
    isVehicleOrTrackingRoute
      ? setUrlReloadTriggered(true)
      : setUrlReloadTriggered(false);
    return function cleanup() {
      store.dispatch(geofencesEmptyState());
      store.dispatch(geofenceCategoriesEmptyState());
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehiclesStatusViewEmptyState());
      store.dispatch(vehiclesViewEmptyState());
      clearTimeout(vehiclesPolling);
    };
  }, []);

  useEffect(() => {
    setPolling({ date: lastUpdatePolling });
  }, [vehiclesStatusViewSliceStatus]);

  /**
   * Every time the state of vehicles slice changes the component checks whether
   * it should invoke a toast, in case of failure, or the loader in case of pending request
   */

  if (
    vehiclesStatusSliceStatus === "failed" &&
    vehiclesStatusSliceReasonCode === GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
  ) {
    console.error("Something went wrong while getting vehicles");
    ToastNotification({
      toastId: "vehicleStatusError",
      status: "error",
      description: t("common.vehicleStatusError"),
    });
  } else if (
    vehiclesStatusSliceStatus === "failed" &&
    vehiclesStatusSliceReasonCode === ""
  ) {
    ToastNotification({
      toastId: "networkError",
      status: "error",
      description: t("common.networkError"),
    });
  }

  // #endregion Vehicles

  // #region public_transport

  fetchPublicTransport = (publicTransportId: number, fleetId: number) => {
    console.log(
      "Loaded public transport id:" + publicTransportId + " data from API"
    );
    dispatch(
      getPublicTransportAsync({ fleetId: fleetId, id: publicTransportId })
    );
  };

  function getStartPublicTransportStop(
    selectedPublicTransportStatus: PublicTransportStatus
  ) {
    let startStop: PublicStopStatus = {} as PublicStopStatus;
    if (
      selectedPublicTransportStatus != null &&
      selectedPublicTransportStatus?.prevPublicStops != null
    ) {
      startStop =
        selectedPublicTransportStatus?.prevPublicStops.find(
          (stop) => stop.publicStopIndex === 1
        ) ?? ({} as PublicStopStatus);
    }
    return startStop;
  }

  function getNextPublicTransportStop(
    selectedPublicTransportStatus: PublicTransportStatus
  ) {
    let nextPublicStopIndex =
      selectedPublicTransportStatus?.nextPublicStop?.publicStopIndex ?? 0;
    let nextPublicStop = {} as PublicStopETA;
    if (nextPublicStopIndex) {
      nextPublicStop =
        selectedPublicTransportStatus?.publicStopETAs.find((publStop) => {
          return publStop?.stopSequence === nextPublicStopIndex;
        }) ?? ({} as PublicStopETA);
    }

    return nextPublicStop;
  }

  function getNextPublicStops(
    selectedPublicTransportStatus: PublicTransportStatus
  ) {
    let tripId =
      selectedPublicTransportStatus?.currPublicStop?.numberOfTrips ?? 0;

    let publicStopETAs: PublicStopETA[] = [];
    if (
      selectedPublicTransportStatus != null &&
      selectedPublicTransportStatus?.publicStopETAs != null
    ) {
      publicStopETAs = [...selectedPublicTransportStatus?.publicStopETAs];
    }

    publicStopETAs = publicStopETAs
      .filter((pseta) => pseta.tripIndex === tripId)
      .reverse();

    return publicStopETAs;
  }

  function getPrevPublicStops(
    selectedPublicTransportStatus: PublicTransportStatus
  ) {
    let prevStops: PublicStopStatus[] = [];
    if (
      selectedPublicTransportStatus != null &&
      selectedPublicTransportStatus.prevPublicStops != null
    ) {
      prevStops = [...selectedPublicTransportStatus.prevPublicStops];

      prevStops = prevStops.filter(
        (prevStop) =>
          prevStop?.publicStopIndex !==
          selectedPublicTransportStatus?.currPublicStop.publicStopIndex
      );

      prevStops = prevStops.filter(
        (stop, index, self) =>
          index === self.findIndex((s) => s.publicStopId === stop.publicStopId)
      );

      prevStops = prevStops.reverse();
    }
    return prevStops;
  }

  // #endregion public_transport

  // #region Vehicle_Modal
  const closeVehicleModalDetails = () => {
    setCurrentActive(-1);
  };

  const openVehicleModalDetails = (vehicleId: number) => {
    setCurrentActive(vehicleId);
  };

  // Handle routes
  const location = useLocation();
  const fleetControlRoute = useMatch("/dashboard/fleet-control");

  let isFleetControlRoute = fleetControlRoute !== null;
  if (isFleetControlRoute) {
    document.title = t("navigation.userMenu.fleetControl");
  }

  const vehicleRoute = useMatch("/dashboard/fleet-control/vehicle/:vehicleId");
  let isVehicleRoute = vehicleRoute !== null;
  const trackingRoute = useMatch(
    "/dashboard/fleet-control/vehicle/:vehicleId/tracking"
  );
  let isTrackingRoute = trackingRoute !== null;
  let isVehicleOrTrackingRoute = isVehicleRoute || isTrackingRoute;

  let currentRoute = isVehicleRoute ? vehicleRoute : trackingRoute;
  const vehicleFromCurrentRoute = useAppSelector((state: any) =>
    vehiclesViewSelectors.selectById(
      state,
      currentRoute?.params?.vehicleId ?? -1
    )
  );

  /**
   * This use effect checks if the requested vehicle from URL exists.
   */
  useEffect(() => {
    if (
      vehicles.length > 0 &&
      !vehicleFromCurrentRoute &&
      !isFleetControlRoute &&
      isVehicleOrTrackingRoute &&
      currentRoute?.params?.vehicleId
    ) {
      ToastNotification({
        toastId: "vehicleNotExists",
        status: "error",
        description: i18next.t("fleetControl.error.vehicleNotExists"),
      });
      navigate("/dashboard/fleet-control");
    }
  }, [vehicles]);

  /**
   * Handle selected vehicles
   * This use effect executes every time the URL change
   */
  useEffect(() => {
    //When URL is wrong navigate to fleet-control
    if (!isFleetControlRoute && !isVehicleRoute && !isTrackingRoute) {
      navigate("/dashboard/fleet-control");
    }

    // The user goes back from vehicle details to fleet control
    // The user goes back from live tracking to fleet control
    if (currentActive !== -1 && isFleetControlRoute) {
      enableTracking && onTracking(false, currentActive);
      selectedVehicleonMap(currentActive, infoVehicle?.fleet);
    }

    // The user goes back from vehicle details to fleet control
    // The user goes back from vehicle details to another vehicle details
    // The user select another vehicle from the list (change selected vehicle)
    const vehicleIdFromVehicleRouteOrTracking =
      vehicleRoute?.params?.vehicleId ?? trackingRoute?.params?.vehicleId;
    if (
      currentActive !== -1 &&
      isVehicleOrTrackingRoute &&
      vehicleFromCurrentRoute?.fleet &&
      vehicleIdFromVehicleRouteOrTracking &&
      parseInt(vehicleIdFromVehicleRouteOrTracking) !== currentActive
    ) {
      selectedVehicleonMap(
        parseInt(vehicleIdFromVehicleRouteOrTracking),
        vehicleFromCurrentRoute?.fleet
      );
    }

    // The user reloads the page with vehicle route /dashboard/fleet-control/vehicle/:vehicleId[/tracking]
    // The user selects a vehicle from list for the first time
    // The user selects a vehicle from the Details/Tracking button of the tooltip when there is no current active
    if (
      isVehicleOrTrackingRoute &&
      currentActive === -1 &&
      vehicleFromCurrentRoute?.id
    ) {
      if (vehicleFromCurrentRoute !== undefined) {
        selectedVehicleonMap(
          vehicleFromCurrentRoute?.id,
          vehicleFromCurrentRoute?.fleet
        );
      }
    }

    // The user goes from vehicle details to tracking without changing current active (no reloading)
    // The user goes from tracking to vehicle details without changing current active (no reloading)
    if (
      currentActive !== -1 &&
      isVehicleOrTrackingRoute &&
      vehicleIdFromVehicleRouteOrTracking &&
      parseInt(vehicleIdFromVehicleRouteOrTracking) === currentActive
    ) {
      if (enableTracking && !isTrackingRoute) {
        onTracking(false, currentActive);
      }
      if (!enableTracking && isTrackingRoute) {
        onTracking(true, currentActive);
      }
    }
  }, [location, vehicleFromCurrentRoute]);

  /**
   *
   * @param {*} vehicle
   * @param {boolean} updateURL
   */
  function selectedVehicleonMap(vehicleId: number, fleetId: number) {
    if (currentActive !== vehicleId) {
      fetchVehicle(vehicleId, fleetId);
      if (gtfs) {
        fetchPublicTransport(vehicleId, fleetId);
      }
      openVehicleModalDetails(vehicleId);
    } else {
      closeVehicleModalDetails();
    }
  }

  useEffect(() => {
    if (queryParams !== "" && currentActive !== -1) {
      closeVehicleModalDetails();
      navigate(`/dashboard/fleet-control`);
    }
  }, [queryParams]);

  /**
   * Handle live tracking.
   * This use effect gets executed every time there is a current active and
   * the URL changes as follows:
   *   - from tracking to details
   *   - from details to tracking
   */
  useEffect(() => {
    // The user go back from live tracking to vehicle details modal
    if (currentActive !== -1 && enableTracking && !isTrackingRoute) {
      onTracking(false, currentActive);
    }

    // The user go forward from vehicle details modal to live tracking
    // The user reload the live tracking page /dashboard/fleet-control/vehicle/:vehicleId/tracking
    if (currentActive !== -1 && !enableTracking && isTrackingRoute) {
      onTracking(true, currentActive);
    }
  }, [currentActive]);

  useEffect(() => {
    if (!_.isEmpty(selectedVehicleStatus)) {
      const routePoints =
        selectedVehicleStatus.dynamicFields?.gpsPositions?.map(
          (gpsPosition) => ({
            latitude: gpsPosition.latitude ?? 0,
            longitude: gpsPosition.longitude ?? 0,
            direction: gpsPosition.direction ?? 0,
            gpsPositionTimestamp: gpsPosition.gpsPositionTimestamp,
          })
        ) || [];

      const sortedRoutePoints = _.sortBy(routePoints, "gpsPositionTimestamp"); // OrderBy gpsPositionTimestamp dec
      setTrackingData(sortedRoutePoints);
    }
  }, [selectedVehicleStatus]);

  useEffect(() => {
    if (currentActive !== -1) {
      let element = document.getElementById(`vehicle-box-${currentActive}`);
      if (element && urlReloadTriggered) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        setUrlReloadTriggered(false);
      }
    }
  }, [vehicleFromCurrentRoute, vehicles, currentActive]);

  /**
   *
   * @param {boolean} enable Enable or disable tracking
   * @param {*} vehicleId Vehicle ID
   */
  const onTracking = async (enable: boolean, vehicleId: number) => {
    if (!isWebSocketConnecting) {
      let vehicleStatusFromRedux = vehiclesStatusViewSelectors.selectById(
        store.getState(),
        vehicleId
      );
      let vehicleDetailsFromRedux = vehiclesViewSelectors.selectById(
        store.getState(),
        vehicleId
      );

      if (
        !_.isEmpty(vehicleDetailsFromRedux) &&
        vehicleDetailsFromRedux?.alias &&
        vehicleDetailsFromRedux?.plate
      ) {
        if (enable) {
          document.title =
            `${vehicleDetailsFromRedux?.alias} ${vehicleDetailsFromRedux?.plate} - ` +
            t("navigation.userMenu.liveTracking");
        } else {
          // Restore zoom on selected vehicle if it is selected (isTrackingRoute)
          if (vehicleRoute) {
            if (
              vehicleStatusFromRedux?.dynamicFields?.latitude &&
              vehicleStatusFromRedux?.dynamicFields?.longitude
            ) {
              setLatitude(vehicleStatusFromRedux?.dynamicFields?.latitude);
              setLongitude(vehicleStatusFromRedux?.dynamicFields?.longitude);
            }
            setZoom(16);
          }
          document.title = t("navigation.userMenu.fleetControl");
        }
      }
      if (vehicleStatusFromRedux?.deviceStatus !== "ONLINE" && enable) {
        ToastNotification({
          toastId: "vehicleNotOnline",
          status: "error",
          description: i18next.t("fleetControl.error.vehicleNotOnline"),
        });
        navigate(`/dashboard/fleet-control/vehicle/${vehicleId}`);
      } else {
        setExpanded(!enable);
        setHidden(!enable ? "" : "none");
        setEnableTracking(enable);
        await webSocketToggleConnect(enable, vehicleId);
        const dataForTracking = enable
          ? ({
              latitude: !!vehicleStatusFromRedux?.dynamicFields?.latitude
                ? vehicleStatusFromRedux.dynamicFields?.latitude
                : 0,
              longitude: !!vehicleStatusFromRedux?.dynamicFields?.longitude
                ? vehicleStatusFromRedux.dynamicFields?.longitude
                : 0,
              direction: !!vehicleStatusFromRedux?.dynamicFields?.direction
                ? vehicleStatusFromRedux.dynamicFields.direction
                : 0,
            } as PointObj)
          : ({} as PointObj);
        setCurrentPoint(dataForTracking);
        if (enable) {
          clearTimeout(vehiclesPolling);
        } else {
          fetchVehicles();
          setTrackingData([]);
        }
      }
    }
  };

  /**
   * This useEffect is in charge of dispatching the getDriverStatusAsync function
   * (which remotely retrieves the status of a driver) every time the current
   * selected driver changes.
   */
  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "idle" &&
      vehiclesStatusSliceReasonCode === GTFleetSuccessCodes.GET &&
      !_.isEmpty(infoVehicle)
    ) {
      if (infoVehicle.driver) {
        dispatch(getDriverStatusAsync(infoVehicle.driver));
      }
    }
  }, [
    currentActive,
    vehiclesStatusSliceStatus,
    vehiclesStatusSliceReasonCode,
    infoVehicle,
  ]);

  // #endregion Vehicle_Modal

  const containsCurrentActiveVehicle = (vehicles: Vehicle[]) => {
    return vehicles.some((vehicle) => vehicle.id === currentActive);
  };

  const justOneFleet =
    fleetViews.length === 1 ||
    _.sum(fleetViews.map((fleetView) => (fleetView.vehiclesSize ? 1 : 0))) ===
      1;

  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const viewListVehicles = () => {
    const justOneGroup = listGroup?.length == 1;
    let fleetObject: FleetView;
    return listGroup.map((groupName, index) => {
      const filteredStatusCount: FleetStatusCounts =
        vehicleStatusAmount?.fleetStatusCounts?.filter(
          (el: FleetStatusCounts) => {
            fleetObject =
              fleetViewsSelectors.selectById(store.getState(), el.fleetId) ??
              ({} as FleetView);
            return fleetObject?.name === groupName.name;
          }
        )[0];

      let countByStatus: VehicleStatusAmount[] = [];

      if (
        !_.isEmpty(filteredStatusCount) &&
        vehicleStatusAmount?.fleetStatusCounts.length > 1
      ) {
        countByStatus = filteredStatusCount.vehicleStatusCounts?.filter(
          (el: VehicleStatusCounts) =>
            el.status !== "UNKNOWN" && el.status !== "OFFLINE"
        );
      }

      const isExpanded =
        justOneGroup ||
        openedGroups.includes(groupName.name) ||
        containsCurrentActiveVehicle(groupName.vehicles) ||
        (!!vehiclesSearchKey && vehiclesSearchKey != "");

      return (
        <React.Fragment key={index}>
          <VehicleBoxGroupButton
            open={isExpanded}
            title={groupName.name}
            vehicleStatusAmount={countByStatus}
            hasCount={!justOneFleet}
            onChange={(isOpen: boolean) => {
              if (isOpen) {
                if (!openedGroups.includes(groupName.name)) {
                  openedGroups.push(groupName.name);
                  const tempOpenedGroups = [...openedGroups];
                  setOpenedGroups(tempOpenedGroups);
                }
              } else {
                if (openedGroups.includes(groupName.name)) {
                  const tempOpenedGroups = openedGroups.filter(
                    (x) => x !== groupName.name
                  );
                  setOpenedGroups(tempOpenedGroups);
                }
              }
            }}
          >
            {groupName.vehicles
              .filter((vehicle) => {
                const driver: Driver =
                  (driversSelectors.selectById(
                    store.getState(),
                    vehicle?.driver
                  ) as Driver) ?? ({} as Driver);
                const dirverName =
                  driver?.firstName && driver?.lastName
                    ? driver?.firstName.toLowerCase() +
                      " " +
                      driver?.lastName?.toLowerCase()
                    : "";
                const filterVehicle =
                  vehiclesSearchKey == "" ||
                  (vehicle?.alias &&
                    vehicle?.alias
                      ?.toLowerCase()
                      .includes(vehiclesSearchKey.toLowerCase())) ||
                  (vehicle?.plate &&
                    vehicle?.plate
                      ?.toLowerCase()
                      .includes(vehiclesSearchKey.toLowerCase())) ||
                  (vehicle?.id &&
                    vehicle?.id
                      ?.toString()
                      ?.toLowerCase()
                      .includes(vehiclesSearchKey.toLowerCase())) ||
                  dirverName.includes(vehiclesSearchKey.toLowerCase());
                return filterVehicle;
              })
              // eslint-disable-next-line array-callback-return
              .map((vehicle, idx) => {
                const vehicleDriver = driversSelectors.selectById(
                  store.getState(),
                  vehicle.driver
                );
                const vehicleStatus = vehiclesStatusViewSelectors.selectById(
                  store.getState(),
                  vehicle.vehicleStatus
                );
                if (!!vehicleStatus) {
                  return (
                    <React.Fragment key={idx}>
                      <VehicleBox
                        id={vehicle.id}
                        lastUpdate={vehicleStatus.dynamicFields?.lastUpdate}
                        vehicle={vehicle}
                        driverName={
                          vehicle.driver
                            ? vehicleDriver?.firstName +
                              " " +
                              vehicleDriver?.lastName
                            : "No driver"
                        }
                        city={
                          vehicleStatus.dynamicFields?.address
                            ? getCitiesFromGPSPositions(
                                vehicleStatus.dynamicFields.address
                              ) +
                              getProvincesFromGPSPositions(
                                vehicleStatus.dynamicFields.address
                              )
                            : t("fleetControl.vehicleDetailsStatus.noCityFound")
                        }
                        signalState={
                          vehicleStatus.dynamicFields?.vehicleStatus ??
                          "UNKNOWN"
                        }
                        alarm={vehicleStatus.dynamicFields?.sos}
                        active={currentActive === vehicle.id}
                        icon={getIllustrationFromVehicleType(vehicle)}
                        hasSubtitleIcon={false}
                        clickCallback={(vehicleFromBox: VehicleBoxType) => {
                          if (currentActive !== vehicleFromBox?.id) {
                            navigate(
                              `/dashboard/fleet-control/vehicle/${vehicleFromBox.id}`
                            );
                          } else {
                            navigate(`/dashboard/fleet-control`);
                          }
                        }}
                      />
                    </React.Fragment>
                  );
                }
              })
              .filter((x) => !!x)}
          </VehicleBoxGroupButton>
        </React.Fragment>
      );
    });
  };

  const getCitiesFromGPSPositions = (address: string) => {
    return getCity(address, t("fleetControl.vehicleDetailsStatus.noCityFound"));
  };

  const getProvincesFromGPSPositions = (address: string) => {
    return getProvince(
      address,
      t("fleetControl.vehicleDetailsStatus.noProvinceFound")
    );
  };

  const expandedUpdated = (value: boolean) => {
    if (preferencesContext !== undefined) {
      let editPreferences = {} as Preferences;
      if (preferencesContext.listOnFleetCont !== value)
        editPreferences.listOnFleetCont = value;
      updateRemotePrefs(editPreferences);
    }
  };

  const updateRemotePrefs = (editPreferences: Preferences) => {
    if (Object.values(editPreferences).length > 0) {
      store.dispatch(
        updatePreferencesAsync({
          id: preferencesContext.id,
          preferences: editPreferences,
        })
      );

      setPreferencesContext({
        ...preferencesContext,
        listOnFleetCont:
          editPreferences.listOnFleetCont ??
          preferencesContext?.listOnFleetCont,
        clusterOnMap:
          editPreferences.clusterOnMap ?? preferencesContext?.clusterOnMap,
        vehIdOnMap:
          editPreferences.vehIdOnMap ?? preferencesContext?.vehIdOnMap,
        tooltipOnMap:
          editPreferences.tooltipOnMap ?? preferencesContext?.tooltipOnMap,
        trafficInfoOnMap:
          editPreferences.trafficInfoOnMap ??
          preferencesContext?.trafficInfoOnMap,
      });
    }
  };
  return (
    <>
      <PageFilters hidden={hidden}>
        <div className="col col-16">
          <FleetControlFilterBar
            selectedVehicleStatus={vehicleStatusFromBadge}
            callback={setQueryParams}
            vehiclesSearch={setVehiclesSearchKey}
          />
        </div>
      </PageFilters>
      <PageContent height={!hidden}>
        <ContentSidebar>
          <Sidebar expanded={expanded} expandedUpdated={expandedUpdated}>
            {listGroup.length > 1 && vehicles.length > 0 ? (
              <>
                <p className="fc-vehicles-count">
                  {t("common.vehicles")} (
                  {
                    vehicles.filter((vehicle) => {
                      const driver: Driver =
                        (driversSelectors.selectById(
                          store.getState(),
                          vehicle?.driver
                        ) as Driver) ?? ({} as Driver);
                      const dirverName =
                        driver?.firstName && driver?.lastName
                          ? driver?.firstName.toLowerCase() +
                            " " +
                            driver?.lastName?.toLowerCase()
                          : "";
                      const filterVehicle =
                        vehiclesSearchKey == "" ||
                        (vehicle?.alias &&
                          vehicle?.alias
                            ?.toLowerCase()
                            .includes(vehiclesSearchKey.toLowerCase())) ||
                        (vehicle?.plate &&
                          vehicle?.plate
                            ?.toLowerCase()
                            .includes(vehiclesSearchKey.toLowerCase())) ||
                        (vehicle?.id &&
                          vehicle?.id
                            ?.toString()
                            ?.toLowerCase()
                            .includes(vehiclesSearchKey.toLowerCase())) ||
                        dirverName.includes(vehiclesSearchKey.toLowerCase());
                      return filterVehicle;
                    }).length
                  }
                  )
                </p>
              </>
            ) : null}
            {vehicleStatusAmount && viewListVehicles()}
          </Sidebar>
        </ContentSidebar>
        <div className="col fleetControl">
          <div className="map-functionalities">
            {!enableTracking && (
              <FleetControlMap
                id={0}
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                latitude={latitude}
                longitude={longitude}
                zoom={zoom}
                hasStreetView={true}
                vehicles={vehicles as Vehicle[]}
                vehiclesStatus={vehiclesStatus as VehicleStatus[]}
                drivers={drivers}
                selectedVehicleStatus={selectedVehicleStatus as VehicleStatus}
                enableTracking={enableTracking}
                isVehicleDetailsOpen={currentActive !== -1}
                geofencesProp={geofences}
                geofenceCategoriesProp={geofenceCategories}
                setExpanded={setExpanded}
                pollingDate={polling}
                vehicleStatusAmount={vehicleStatusAmount}
                vehicleStatusFromBadge={setVehicleStatusFromBadge}
                pollingRefresh={() => {
                  clearTimeout(vehiclesPolling);
                  fetchVehicles();
                }}
                streetViewEnabledFleetControl={setStreetViewEnabled}
              />
            )}
            {enableTracking && (
              <LiveTrackingMap
                id={0}
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                zoom={3}
                hasStreetView={true}
                latitude={41.9109}
                longitude={12.4818}
                currentPoint={currentPoint}
                trackingPoints={trackingData}
              />
            )}
            {!streetViewEnabled &&
              currentActive !== -1 &&
              !_!.isEmpty(infoVehicle) &&
              infoVehicle?.id &&
              selectedVehicleStatus?.id && (
                <div
                  className={"openVehicleDetails " + (hidden ? "widenDiv" : "")}
                >
                  <VehicleDetailsModal>
                    <VehicleDetailsModal.Header
                      signalState={
                        selectedVehicleStatus.dynamicFields
                          ? selectedVehicleStatus.dynamicFields?.vehicleStatus
                          : "UNKNOWN"
                      }
                      vehicleId={infoVehicle.alias ?? "N/A"}
                      vehicleLicensePlate={infoVehicle.plate ?? "VFF"}
                      icon={getIllustrationFromVehicleType(infoVehicle)}
                    />
                    <div
                      className={
                        isTrackingRoute
                          ? "fc-vehicle-modal-container-tracking"
                          : "fc-vehicle-modal-container"
                      }
                    >
                      <div
                        style={{
                          height: "100%",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <VehicleDetailsModal.Status
                          currentPosition={
                            selectedVehicleStatus?.dynamicFields?.address
                              ? selectedVehicleStatus?.dynamicFields?.address
                              : t("common.na")
                          }
                          positions={
                            selectedVehicleStatus.dynamicFields
                              ?.gpsPositions ?? [t("common.na")]
                          }
                          disabled={!permissions.liveTrack.read}
                          liveData={{
                            currentSpeed:
                              selectedVehicleStatus.dynamicFields?.speed ?? 0,
                            speedBar: 90,
                            currentAutonomy:
                              selectedVehicleStatus?.dynamicFields
                                ?.fuelLevelLiters && infoVehicle?.consumption
                                ? selectedVehicleStatus?.dynamicFields
                                    ?.fuelLevelLiters * infoVehicle?.consumption
                                : "",
                            autonomyBar: 25,
                          }}
                          onTracking={(isTracking) => {
                            if (isTracking) {
                              navigate(
                                `/dashboard/fleet-control/vehicle/${currentActive}`
                              );
                            } else {
                              window
                                .open(
                                  `/dashboard/fleet-control/vehicle/${currentActive}/tracking`,
                                  "_blank"
                                )
                                ?.focus();
                            }
                          }}
                          status={
                            selectedVehicleStatus.dynamicFields
                              ? selectedVehicleStatus.dynamicFields
                                  ?.vehicleStatus
                              : "UNKNOWN"
                          }
                          isTracking={isTrackingRoute}
                          lastUpdate={
                            selectedVehicleStatus?.dynamicFields?.lastUpdate
                          }
                          gtfs={gtfs}
                          publicTransportStatus={selectedPublicTransportStatus}
                          nextStopIndex={
                            selectedPublicTransportStatus?.nextPublicStop
                              ?.publicStopIndex ?? 1
                          }
                          startPublicStop={getStartPublicTransportStop(
                            selectedPublicTransportStatus
                          )}
                          nextPublicStop={getNextPublicTransportStop(
                            selectedPublicTransportStatus
                          )}
                          nextPublicStops={getNextPublicStops(
                            selectedPublicTransportStatus
                          )}
                          prevStops={getPrevPublicStops(
                            selectedPublicTransportStatus
                          )}
                        />
                        {gtfs && selectedPublicTransportStatus != null && (
                          <VehicleDetailsModal.PubliTransportDetails
                            routeName={selectedPublicTransportStatus?.routeName}
                            tripIndex={
                              selectedPublicTransportStatus?.currPublicStop
                                ?.numberOfTrips
                            }
                          ></VehicleDetailsModal.PubliTransportDetails>
                        )}
                        <VehicleDetailsModal.VehicleDetails
                          isTracking={isTrackingRoute}
                          liveData={{
                            currentSpeed:
                              selectedVehicleStatus.dynamicFields?.speed ?? 0,
                            speedBar: 90,
                            currentAutonomy:
                              selectedVehicleStatus?.dynamicFields
                                ?.fuelLevelLiters && infoVehicle?.consumption
                                ? selectedVehicleStatus?.dynamicFields
                                    ?.fuelLevelLiters * infoVehicle?.consumption
                                : "",
                            autonomyBar: 25,
                          }}
                          vehicle={
                            (infoVehicle as VehiclePropType) ??
                            ({} as VehiclePropType)
                          }
                          vehicleStatus={
                            (selectedVehicleStatus as VehicleStatusPropType) ??
                            ({} as VehicleStatusPropType)
                          }
                          fleetView={
                            (fleetView as FleetPropType) ??
                            ({} as FleetPropType)
                          }
                        />

                        <VehicleDetailsModal.DriverDetails
                          vehicle={
                            (infoVehicle as VehiclePropType) ??
                            ({} as VehiclePropType)
                          }
                          driver={
                            (driver as DriverPropType) ?? ({} as DriverPropType)
                          }
                          driverStatus={
                            (driverStatus as DriverStatusPropType) ??
                            ({} as DriverStatusPropType)
                          }
                          vehicleType={
                            infoVehicle?.type ? infoVehicle.type : ""
                          }
                        />

                        {assets.length > 0 ? (
                          <VehicleDetailsModal.AssetDetails
                            assets={assets as AssetPropType[]}
                          />
                        ) : null}
                      </div>
                      <div className="fc-sidebar-collapse-container">
                        <div
                          className="fc-sidebar-collapse"
                          onClick={() => navigate("/dashboard/fleet-control")}
                        >
                          <IconClose
                            size={10}
                            color="--global-colors-ink-dark"
                          />
                          <span>{t("common.close")}</span>
                        </div>
                      </div>
                    </div>
                  </VehicleDetailsModal>
                </div>
              )}
          </div>
        </div>
      </PageContent>
    </>
  );
};
export default FleetControl;
