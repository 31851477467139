import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next, { t } from "i18next";
import _ from "lodash";
import { GTFleetSuccessCodes } from "../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../config/GTfleetErrorCodes";
import { reportType } from "../features/report/reportsMetadataSlice";
import { IconCar } from "../ui/Icon/Line/Car";
import { IconMotorbike } from "../ui/Icon/Line/Motorbike";
import { IconOperatingMachine } from "../ui/Icon/Line/OperatingMachine";
import { IconTrailerTruck } from "../ui/Icon/Line/TrailerTruck";
import { IconVehicle } from "../ui/Icon/Line/Vehicle";
import { IconBus } from "../ui/Icon/Solid/Bus";
import { IconMotorcycle } from "../ui/Icon/Solid/Motorcycle";
import { IconUser } from "../ui/Icon/Solid/User";
import IllustrationAirplane from "../ui/Illustration/Airplane";
import IllustrationAmbulance from "../ui/Illustration/Ambulance";
import IllustrationAutobus from "../ui/Illustration/Autobus";
import IllustrationBike from "../ui/Illustration/Bike";
import IllustrationCamper from "../ui/Illustration/Camper";
import IllustrationCar from "../ui/Illustration/Car";
import IllustrationHumanBeing from "../ui/Illustration/HumanBeing";
import IllustrationMiniExcavator from "../ui/Illustration/MiniExcavator";
import IllustrationTrailerCar from "../ui/Illustration/TrailerCar";
import IllustrationTrailerTruck from "../ui/Illustration/TrailerTruck";
import IllustrationTruck from "../ui/Illustration/Truck";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Check if the stylesheet is internal or hosted on the current domain.
 * If it isn't, attempting to access sheet.cssRules will throw a cross origin
 * error.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet#Notes
 * @param {object} stylesheet stylesheet retrieve from the webpage
 * @returns boolean
 */
const isSameDomain = (stylesheet) => {
  if (!stylesheet.href) {
    return true;
  }

  return stylesheet.href.indexOf(window.location.origin) === 0;
};

export const getParamString = (
  queryParamName,
  queryParamValues,
  delimitator
) => {
  let queryParamValueArray;
  let queryString = "";
  if (Array.isArray(queryParamValues)) {
    queryParamValueArray = queryParamValues;
  } else if (typeof queryParamValues === "number") {
    queryParamValueArray = [queryParamValues];
  } else if (typeof queryParamValues === "boolean") {
    queryParamValueArray = [queryParamValues.toString()];
  } else {
    queryParamValueArray = queryParamValues?.split(delimitator);
  }
  for (const queryParamValue of queryParamValueArray) {
    const operator = queryString.length === 0 ? "" : "&";
    if (!!queryParamValue) {
      queryString += `${operator}${queryParamName}=${encodeURIComponent(
        queryParamValue
      )}`;
    }
  }
  return queryString;
};

export const getQueryString = (allQueryParams, delimitator) => {
  let queryString = "";
  for (const queryParamName in allQueryParams) {
    const queryParamValues = allQueryParams[queryParamName];
    const operator = queryString.length === 0 ? "?" : "&";
    const result = getParamString(
      queryParamName,
      queryParamValues,
      delimitator
    );
    queryString += `${operator}${result}`;
  }
  return queryString.replaceAll("%20", "+");
};

/**
 * Determine if the given rule is a CSSStyleRule
 * @see: https://developer.mozilla.org/en-US/docs/Web/API/CSSRule#Type_constants
 *
 * @param {String} rule
 * @returns Boolean
 */
const isStyleRule = (rule) => rule.type === 1;

/**
 * Get all custom properties on a page.
 *
 * @returns array<array[string, string]>
 * ex; [["--color-accent", "#b9f500"], ["--color-text", "#252525"], ...]
 */
const getCSSCustomPropIndex = () =>
  // styleSheets is array-like, so we convert it to an array.
  // Filter out any stylesheets not on this domain
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        // cssRules is array-like, so we convert it to an array
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [
              propName.trim(),
              rule.style.getPropertyValue(propName).trim(),
            ])
            // Discard any props that don't start with "--". Custom props are required to.
            .filter(([propName]) => propName.indexOf("--") === 0);

          return [...propValArr, ...props];
        }, [])
      ),
    []
  );

export const cssCustomPropIndex = getCSSCustomPropIndex();

/**
 * Search string in object array variable.
 * @param {Object} object the array variable
 * @param {String} ToSearch string
 * @returns {Boolean} results of the evaluation
 */
export const searchObject = (objects, toSearch) => {
  for (const [key, value] of Object.entries(objects)) {
    if (objects[key] && typeof objects[key] === "object") {
      let result = searchObject(value, toSearch);
      if (result) {
        return result;
      }
    } else if (objects[key] && Array.isArray(objects[key])) {
      objects[key].forEach((obj) => {
        return searchObject(obj, toSearch);
      });
    } else {
      if (
        objects[key] &&
        objects[key]
          .toString()
          .toLocaleLowerCase()
          .includes(toSearch.toLocaleLowerCase())
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getIllustrationFromVehicleType = (vehicleInfo, size = 30) => {
  const defaultIllustration = <IllustrationCar size={size} />;
  if (!vehicleInfo) return defaultIllustration;

  switch (vehicleInfo.type) {
    case "TRUCK":
      return <IllustrationTruck size={size} />;
    case "AMBULANCE":
      return <IllustrationAmbulance size={size} />;
    case "AUTOBUS":
      return <IllustrationAutobus size={size} />;
    case "BIKE":
      return <IllustrationBike size={size} />;
    case "AIRPLANE":
      return <IllustrationAirplane size={size} />;
    case "HUMAN_BEING":
      return <IllustrationHumanBeing size={size - 3} />;
    case "CAMPER":
      return <IllustrationCamper size={size} />;
    case "TRAILER_CAR":
      return <IllustrationTrailerCar size={size} />;
    case "TRAILER":
      return <IllustrationTrailerTruck size={size} />;
    case "OPERATING_MACHINE":
      return <IllustrationMiniExcavator size={size} />;
    default:
      return defaultIllustration;
  }
};

export const getIconFromVehicleType = (type, size, color) => {
  const defaultIcon = <IconCar size={size} color={color} />;
  if (!type) return defaultIcon;

  switch (type) {
    case "TRUCK":
      return <IconVehicle size={size} color={color} />;
    case "HUMAN_BEING":
      return <IconUser size={size} color={color} />;
    case "SCOOTER":
      return <IconMotorcycle size={size} color={color} />;
    case "BIKE":
      return <IconMotorbike size={14} color={color} />;
    case "TRAILER_CAR":
      return <IconTrailerTruck size={size} color={color} />;
    case "TRAILER":
      return <IconTrailerTruck size={size} color={color} />;
    case "OPERATING_MACHINE":
      return <IconOperatingMachine size={size} color={color} />;
    case "AUTOBUS":
      return <IconBus size={size} color={color} />;
    default:
      return defaultIcon;
  }
};

/**
 * Convert meters To Kilometers
 * 1 m is equivalent to 0.001 km
 * @param {Number} mt
 * @param {Number} decimals
 * @returns {Float}
 */
export const mtToKm = (mt, decimals = 2) => {
  return Number((mt / 1000).toFixed(decimals));
};

/**
 * Convert Kilometers to meters
 * 1 km is equivalent to 1000 m
 * @param {Number} mt
 * @param {Number} decimals
 * @returns {Float}
 */
export const kmToMt = (mt, decimals = 2) => {
  return Number((mt * 1000).toFixed(decimals));
};

/**
 * Convert Miles to Meters
 * 1 mil is equivalent to 1609.344 mt.
 * @param {Number} mt
 * @param {Number} decimals
 * @returns {Float}
 */
export const milesToMt = (miles, decimals = 2) => {
  return Number((miles * 1609.344).toFixed(decimals));
};

/**
 * Convert Meters to Miles
 * 1 mt is equivalent to 0.000621371 miles.
 * @param {Number} mt
 * @param {Number} decimals
 * @returns {Float}
 */
export const mtToMiles = (mt, decimals = 2) => {
  return Number((mt * 1609.344).toFixed(decimals));
};

/**
 * Convert Kilometers or Miles to meters by preferences
 * 1 km is equivalent to 1000 m or 0.6214 miles
 * @param {Number} measure
 * @param {Number} decimals
 * @returns {Float}
 */
export const getMeter = (measure, isMetric) => {
  if (isMetric) {
    return kmToMt(measure);
  } else {
    return milesToMt(measure);
  }
};

/**
 * Convert mt to km or miles by preferences
 * 1 km is equivalent to 1000 m or 0.6214 miles
 * @param {Number} measure
 * @param {Number} decimals
 * @returns {Float}
 */
export const mtToDynamicMeasure = (measure, isMetric) => {
  if (isMetric) {
    return mtToKm(measure);
  } else {
    return mtToMiles(measure);
  }
};

/**
 * Convert meters To Foot
 * 1 m is equivalent to 3.28084 ft
 * @param {Number} mt
 * @param {Number} decimals
 * @returns {Float}
 */
export const mtToFt = (mt, decimals = 2) => {
  return Number((mt * 3.281).toFixed(decimals));
};

/**
 * Convert Kilometers to Miles.
 * 1Km is equivalent to 0.6214 miles.
 * Formula:
 * mi = km * 0.62137
 * @param {Float} km
 * @returns {Float}
 */
export const kmToMiles = (km) => {
  return km * 0.621371;
};

/**
 * Convert Kilometers Per Hour to Miles Per Hour.
 * Formula:
 * mph = km/h ÷ 1.609344
 * @param {*} kmPerH
 * @returns
 */
export const kmPerHToMilesPerH = (kmPerH) => {
  return kmPerH / 1.609344;
};

/**
 * Convert Kilometers per Liters to Miles per Gallon (US).
 * Formula:
 * mpg (US) = km/L * 2.352145
 * @param {*} kmPerLt
 * @returns
 */
export const kmPerLtToMilesPerG = (kmPerLt) => {
  return kmPerLt * 2.3521458;
};

/**
 * Convert Miles per Gallon to Kilometers per Liters (US).
 * Formula:
 * km/L = mpg (US) / 2.352145
 * @param {*} kmPerLt
 * @returns
 */
export const milesPerGTokmPerLt = (milesPerG) => {
  return milesPerG / 2.3521458;
};

/**
 * Convert Liters to Gallons.
 * Formula:
 * gal = liters * 0.264172
 * @param {*} lt
 * @returns
 */
export const ltToGal = (lt) => {
  return lt * 0.264172;
};

/**
 * Convert Eur to Dollars (workaround).
 * Formula:
 * dollars = eur * 1.07
 * @param {*} eur
 * @returns
 */
const eurToDollar = (eur) => {
  return eur * 1.07;
};

/**
 * Convert Eur to Pound (workaround).
 * Formula:
 * dollars = eur * 0.88
 * @param {*} Lt
 * @returns
 */
const eurToPound = (eur) => {
  return eur * 0.88;
};

/**
 * Convert Eur to Currency.
 * @param {*} currency of type EURO, DOLLAR or POUNT_STERLING
 * @param {*} euro the number representing the euro value
 * @returns return the converted number or 0 in case of non valid data.
 */
export const convertEuroOnCurrency = (currency, euro) => {
  try {
    if (currency === "DOLLAR") {
      return eurToDollar(euro);
    }
    if (currency === "POUND_STERLING") {
      return eurToPound(euro);
    }
    return euro;
  } catch (error) {
    return 0;
  }
};

/**
 * Convert number with the correct annotation.
 * @param {Number} number
 * @param {Number} decimals
 * @returns {Number}
 */
export const numberAnnotation = (number, decimals = 2, minDecimal = 0) => {
  return number.toLocaleString(i18next.language, {
    style: "decimal",
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: decimals,
  });
};

/**
 * Truncate decimal numbers based on the specified length.
 * @param {Number} number
 * @param {Number} decimals
 * @returns {Number}
 */
export const truncateDecimalNumber = (number, decimals) => {
  let decimalSeparator = i18next.language === "it" ? "," : ".";
  let numberSeparatedByDecimals = number.value.split(decimalSeparator);

  if (numberSeparatedByDecimals.length < 2) {
    return number.value;
  }
  if (numberSeparatedByDecimals[1].length > decimals) {
    numberSeparatedByDecimals[1] = numberSeparatedByDecimals[1].substr(
      0,
      numberSeparatedByDecimals[1].length - 1
    );
  }

  return numberSeparatedByDecimals.join(decimalSeparator);
};

/**
 * this method is in charge of format data according to unit
 * of measure set in the preferences
 * @param {*} data
 * @param {*} convertFunction
 * @param {*} preferencesContext
 * @returns
 */
export const formatData = (
  data,
  convertFunction,
  preferencesContext,
  decimals = 2,
  minDecimal = 0
) => {
  if (!data) return 0;
  if (preferencesContext.isMetric)
    return numberAnnotation(data, decimals, minDecimal);
  else
    return numberAnnotation(
      convertFunction ? parseFloat(convertFunction(data)) : parseFloat(data),
      decimals,
      minDecimal
    );
};

/**
 * Given two positions, return the linear distance in meters beetween them
 * @param {Number} lat1
 * @param {Number} lon1
 * @param {Number} lat2
 * @param {Number} lon2
 * @returns {Number}
 */
export const getLinearDistance = (lat1, lon1, lat2, lon2) => {
  let radlat1 = (Math.PI * lat1) / 180;
  let radlat2 = (Math.PI * lat2) / 180;
  let theta = lon1 - lon2;
  let radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;
  return dist * 1000;
};

export const getCity = (address, defaultAddress) => {
  if (!!address) {
    let lastPositionSplitted = address.split(",");
    let city = "";
    let citySplitted = lastPositionSplitted[lastPositionSplitted.length - 2]
      .trim()
      .split(" ");
    let state = lastPositionSplitted[lastPositionSplitted.length - 1].trim();
    if (isNaN(citySplitted[0])) {
      city = citySplitted[0] + " ";
    }
    for (let index = 1; index < citySplitted.length - 1; index++) {
      city = city + citySplitted[index] + " ";
    }
    if (state !== "Italia") {
      city = city + citySplitted[citySplitted.length - 1];
    }
    return city;
  }
  return defaultAddress;
};

export const getProvince = (address, defaultAddress) => {
  if (!!address) {
    let lastPositionSplitted = address.split(",");
    let province = "";
    let citySplitted = lastPositionSplitted[lastPositionSplitted.length - 2]
      .trim()
      .split(" ");
    province = citySplitted[citySplitted.length - 1];
    return "(" + province + ")";
  }
  return defaultAddress;
};

export const getAddress = (address, defaultAddress) => {
  if (!!address) {
    let lastPositionSplitted = address.split(",");
    let getAddress = "";
    let addressSplitted = lastPositionSplitted[0].trim();
    if (isNaN(addressSplitted)) {
      getAddress = addressSplitted;
    }
    if (Number(lastPositionSplitted[1])) {
      getAddress += "," + lastPositionSplitted[1];
    }
    return getAddress;
  }
  return defaultAddress;
};

export const getAddressView = (address) => {
  let addressSplitted = address?.split(",").map(function (item) {
    return item.trim();
  });
  let addressString = addressSplitted[0];
  if (addressSplitted[1]) {
    if (addressSplitted[1] && addressSplitted[1].length < 5) {
      addressString += ", " + addressSplitted[1];
      if (!isNaN(parseInt(addressSplitted[2][0]))) {
        addressString +=
          ", " + addressSplitted[2].substring(5, addressSplitted[2].length - 2);
      }
    } else if (!isNaN(parseInt(addressSplitted[1][0]))) {
      addressString +=
        ", " + addressSplitted[1].substring(5, addressSplitted[1].length - 2);
    } else if (
      !isNaN(parseInt(addressSplitted[1][addressSplitted[1].length - 1]))
    ) {
      addressString +=
        ", " + addressSplitted[1].substring(0, addressSplitted[1].length - 5);
    } else {
      addressString += ", " + addressSplitted[1];
    }
  }
  return addressString;
};

export const getReportRoute = (title) => {
  switch (title) {
    case reportType.VEHICLE_STOP_REPORT:
    case reportType.VEHICLE_TRAVEL_REPORT:
      return "vehicle-travel-report";
    case reportType.GPSDATA_ROUTE_REPORT:
    case reportType.GPSDATA_STOP_REPORT:
      return "gps-data";
    case reportType.EVENTS_IO_REPORT:
      return "io-report";
    case reportType.DRIVER_TRAVEL_REPORT:
      return "driver-travel-report";
    case reportType.GEOFENCE_ROUTE_REPORT:
    case reportType.GEOFENCE_STOP_REPORT:
    case reportType.GEOFENCE_ROUTE_AND_STOP_REPORT:
      return "geofence-report";
    case reportType.REFUELLING_REPORT:
      return "refuelling-report";
    case reportType.DAILY_ACTIVITY_REPORT:
      return "archive/daily-activity";
    case reportType.INFRINGEMENT_REPORT:
      return "archive/infringement-letter";
    case reportType.UTILIZATION_REPORT:
      return "utilization-report";
    default:
      return "";
  }
};

export const getReportRouteNameTranslation = (title) => {
  return t("reports.types." + title);
};

export const approx = (num) => {
  let round_down = Math.floor(num);
  let round_up = Math.ceil(num);
  return round_up - num <= num - round_down ? round_up : round_down;
};

/**
 * GTFleetErrorCodes switch case with all cases
 */
export const getErrorCodes = (payload) => {
  if (Object.values(GTFleetErrorCodes).includes(payload)) {
    return payload;
  }
  return "";
};

export const getPresetsNotification = (state, reasonCode) => {
  if (state === "failed") {
    if (reasonCode === GTFleetErrorCodes.PRESET_NOT_FOUND) {
      return {
        status: "error",
        description: "common.presetsError",
      };
    } else if (reasonCode === GTFleetErrorCodes.PRESET_NAME_ALREADY_EXISTS) {
      return {
        status: "error",
        description: "common.presetsNameError",
      };
    } else if (reasonCode === "") {
      return {
        status: "error",
        description: "common.networkError",
      };
    } else if (reasonCode === GTFleetErrorCodes.USER_UNAUTHORIZED) {
      return {
        status: "error",
        description: "common.UserNotFoundError",
      };
    } else if (reasonCode === GTFleetErrorCodes.PRESET_MISSING_INFO) {
      return {
        status: "error",
        description: "common.presetsRemovedError",
      };
    }
  } else if (state === "idle") {
    if (reasonCode === GTFleetSuccessCodes.POST) {
      return {
        status: "success",
        description: "common.newPresetsSuccess",
      };
    } else if (reasonCode === GTFleetSuccessCodes.PATCH) {
      return {
        status: "success",
        description: "common.presetsUpdateSuccess",
      };
    } else if (reasonCode === GTFleetSuccessCodes.DELETE) {
      return {
        status: "success",
        description: "common.presetsDeleteSuccess",
      };
    }
  }
  return {
    status: undefined,
    description: undefined,
  };
};

export function getBoundsZoomLevel(bounds, mapDim) {
  let WORLD_DIM = { height: 256, width: 256 };
  let ZOOM_MAX = 21;

  function latRad(lat) {
    let sin = Math.sin((lat * Math.PI) / 180);
    let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  let ne = bounds.getNorthEast();
  let sw = bounds.getSouthWest();

  let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  let lngDiff = ne.lng() - sw.lng();
  let lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  let latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  let lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

/**
 * Destructures payload to autocomplete address fields
 * @param data payload from google places autocomplete search
 * @param state the state of entity to update (user, driver, tenant)
 * @param setState method to update entity state
 */
export const autoCompleteAddressFields = (data, setDisabledFields) => {
  let streetNumber = "",
    address = "",
    city,
    province,
    country,
    zip;

  data?.address_components?.forEach((component) => {
    switch (component?.types[0]) {
      case "street_number":
        streetNumber = component?.long_name ?? "";
        break;
      case "route":
        address = component?.long_name ?? "";
        break;
      case "administrative_area_level_3":
        city = component?.long_name ?? "";
        break;
      case "administrative_area_level_2":
        province = component?.short_name ?? "";
        break;
      case "country":
        country = component?.long_name ?? "";
        break;
      case "postal_code":
        zip = component?.long_name ?? "";
        break;
      default:
        break;
    }
  });

  setDisabledFields(true);
  return {
    address: address + " " + streetNumber,
    city: city ?? "",
    province: province ?? "",
    zip: zip ?? "",
    country: country ?? "",
    streetNumber: streetNumber ?? "",
  };
};

/**
 * Method for add zeroes in NumberFormat component
 */
export const addZeroes = (value, setValue, preferencesContext) => {
  dayjs.locale(preferencesContext.language ?? "en");
  if (value === "") {
    setValue("");
  } else if (
    !value.includes(preferencesContext.language === "it" ? "," : ".")
  ) {
    let val = value;
    val += preferencesContext.language === "it" ? ",00" : ".00";
    setValue(val);
  } else {
    let alternativeVal = value.substring(
      value.indexOf(preferencesContext.language === "it" ? "," : ".") + 1
    );
    if (alternativeVal.length === 1) {
      setValue(value + "0");
    }
  }
};

export function areGPSCoordinates(testString) {
  var coordinatePattern =
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  return coordinatePattern.test(testString);
}

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const formattedDate = `${day}/${month}/${year}`;

  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate} | ${formattedTime}`;
}

export function handleStatusColor(status) {
  switch (status) {
    case "WARNING":
      return "var(--global-colors-feedback-warning)";
    case "INFO":
      return "var(--global-colors-ui-primary)";
    case "ALARM":
      return "var(--global-colors-feedback-danger)";
    case "PARKING":
      return "var(--global-colors-ui-primary)";
    default:
  }
}

export function convertMillisecondsToTime(ms) {
  // 1 secondo = 1000 millisecondi
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formattedHours = String(hours).padStart(2, "0"); // Rimuovo il modulo 24 per evitare il reset a 0
  const formattedMinutes = String(minutes % 60).padStart(2, "0");
  const formattedSeconds = String(seconds % 60).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function formatMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  let result = "";

  if (hours > 0 || mins > 0) {
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (mins > 0) {
      result += `${mins}m`;
    }
  } else {
    result = "0h";
  }

  return result.trim();
}

export function convertMillisecondsToShortedTime(ms) {
  // 1 secondo = 1000 millisecondi
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes % 60).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function getDayOfWeek(dayNumber) {
  const daysOfWeek = [
    "common.datePicker.days.Sunday",
    "common.datePicker.days.Monday",
    "common.datePicker.days.Tuesday",
    "common.datePicker.days.Wednesday",
    "common.datePicker.days.Thursday",
    "common.datePicker.days.Friday",
    "common.datePicker.days.Saturday",
  ];
  return daysOfWeek[dayNumber];
}

export function getMonthStringified(month) {
  const months = [
    "common.datePicker.months.January",
    "common.datePicker.months.February",
    "common.datePicker.months.March",
    "common.datePicker.months.April",
    "common.datePicker.months.May",
    "common.datePicker.months.June",
    "common.datePicker.months.July",
    "common.datePicker.months.August",
    "common.datePicker.months.September",
    "common.datePicker.months.October",
    "common.datePicker.months.November",
    "common.datePicker.months.December",
  ];
  return months[month];
}

export const convertWeekOfMontInInteger = (week) => {
  switch (week) {
    case "FIRST":
      return 1;
    case "SECOND":
      return 2;
    case "THIRD":
      return 3;
    case "FOURTH":
      return 4;
    case "LAST":
      return 5;
    default:
      break;
  }
};

export const convertIntegerInWeekOfMont = (week) => {
  switch (week) {
    case 1:
      return { label: t("weeksOfMonth.first"), value: "FIRST" };
    case 2:
      return { label: t("weeksOfMonth.second"), value: "SECOND" };
    case 3:
      return { label: t("weeksOfMonth.third"), value: "THIRD" };
    case 4:
      return { label: t("weeksOfMonth.fourth"), value: "FOURTH" };
    case 5:
      return { label: t("weeksOfMonth.last"), value: "LAST" };
    default:
      break;
  }
};

export const periodEnumManagement = (periodEnum) => {
  if (periodEnum != undefined && periodEnum != "") {
    if (_.isEqual(periodEnum, "DAILY")) {
      return {
        label: t("admin.notifications.actions.period.daily"),
        value: periodEnum,
      };
    } else if (_.isEqual(periodEnum, "WEEKLY")) {
      return {
        label: t("admin.notifications.actions.period.weekly"),
        value: periodEnum,
      };
    } else if (_.isEqual(periodEnum, "MONTHLY")) {
      return {
        label: t("admin.notifications.actions.period.monthly"),
        value: periodEnum,
      };
    }
  }
};

export const dayOfWeekManagement = (dayOfWeeks) => {
  if (dayOfWeeks !== undefined && dayOfWeeks !== "" && dayOfWeeks !== null) {
    let objDays = dayOfWeeks
      .split(",")
      .map((dayOfWeek) => {
        dayOfWeek = dayOfWeek.trim();
        switch (dayOfWeek) {
          case "MONDAY":
            return {
              label: t("common.datePicker.days.Monday"),
              value: dayOfWeek,
            };
          case "TUESDAY":
            return {
              label: t("common.datePicker.days.Tuesday"),
              value: dayOfWeek,
            };
          case "WEDNESDAY":
            return {
              label: t("common.datePicker.days.Wednesday"),
              value: dayOfWeek,
            };
          case "THURSDAY":
            return {
              label: t("common.datePicker.days.Thursday"),
              value: dayOfWeek,
            };
          case "FRIDAY":
            return {
              label: t("common.datePicker.days.Friday"),
              value: dayOfWeek,
            };
          case "SATURDAY":
            return {
              label: t("common.datePicker.days.Saturday"),
              value: dayOfWeek,
            };
          case "SUNDAY":
            return {
              label: t("common.datePicker.days.Sunday"),
              value: dayOfWeek,
            };
          default:
            return null;
        }
      })
      .filter((day) => day !== null);

    return objDays;
  }
  return [];
};

export const monthsOfYearManagement = (monthsOfYear) => {
  if (
    monthsOfYear !== undefined &&
    monthsOfYear !== "" &&
    monthsOfYear !== null
  ) {
    // Mappa i mesi dell'anno
    let objMonths = monthsOfYear
      .split(",")
      .map((monthOfYear) => {
        monthOfYear = monthOfYear.trim();
        switch (monthOfYear) {
          case "JANUARY":
            return {
              label: t("common.datePicker.months.January"),
              value: monthOfYear,
            };
          case "FEBRUARY":
            return {
              label: t("common.datePicker.months.February"),
              value: monthOfYear,
            };
          case "MARCH":
            return {
              label: t("common.datePicker.months.March"),
              value: monthOfYear,
            };
          case "APRIL":
            return {
              label: t("common.datePicker.months.April"),
              value: monthOfYear,
            };
          case "MAY":
            return {
              label: t("common.datePicker.months.May"),
              value: monthOfYear,
            };
          case "JUNE":
            return {
              label: t("common.datePicker.months.June"),
              value: monthOfYear,
            };
          case "JULY":
            return {
              label: t("common.datePicker.months.July"),
              value: monthOfYear,
            };
          case "AUGUST":
            return {
              label: t("common.datePicker.months.August"),
              value: monthOfYear,
            };
          case "SEPTEMBER":
            return {
              label: t("common.datePicker.months.September"),
              value: monthOfYear,
            };
          case "OCTOBER":
            return {
              label: t("common.datePicker.months.October"),
              value: monthOfYear,
            };
          case "NOVEMBER":
            return {
              label: t("common.datePicker.months.November"),
              value: monthOfYear,
            };
          case "DECEMBER":
            return {
              label: t("common.datePicker.months.December"),
              value: monthOfYear,
            };
          default:
            return null;
        }
      })
      .filter((month) => month !== null);

    return objMonths;
  }
  return [];
};
export function convertDaysOfWeek(daysOfWeek) {
  const daysMap = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  return daysOfWeek?.map((day) => daysMap.indexOf(day));
}

export function convertMonths(months) {
  const monthsMap = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  return months?.map((month) => monthsMap.indexOf(month));
}
